import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMediaQuery } from './shared-functions.js';
import { Row } from 'react-bootstrap';
import { ThemeContext } from "./Theme.js";
import { useRequireAuth } from './use-require-auth.js';
import toast, { Toaster } from 'react-hot-toast';
import { Text, Spinner, DropdownMenu, Button, AlertDialog, VisuallyHidden } from '@radix-ui/themes';
import { FontBoldIcon, FontItalicIcon, PilcrowIcon, QuoteIcon, TrashIcon, UnderlineIcon } from '@radix-ui/react-icons';
import { ArrowCounterClockwise, ArrowsClockwise, CodeSimple, LinkSimple, ListBullets, ListChecks, ListNumbers, MarkdownLogo, SealCheck, SquaresFour, TextHOne, TextHThree, TextHTwo, TextStrikethrough, TextTSlash, Translate, X, XCircle } from '@phosphor-icons/react';
import { BubbleMenu, EditorContent, FloatingMenu, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import Link from '@tiptap/extension-link';
import Image from '@tiptap/extension-image';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight';
import CharacterCount from '@tiptap/extension-character-count';
import Subscript from '@tiptap/extension-subscript';
import Superscript from '@tiptap/extension-superscript';
import TaskItem from '@tiptap/extension-task-item';
import TaskList from '@tiptap/extension-task-list';
import { all, createLowlight } from 'lowlight';
import { dbGetNote, dbTrashNote, dbUpdateNote } from './utilities/sqldb.js';
import { Title } from './components/note/Title.js';
import { NOTE_LANGUAGES, NOTE_TEMPLATE_CATEGORIES, NOTE_TEMPLATES, PROMPT_SYSTEM_CHANGE_READING_LEVEL, PROMPT_SYSTEM_CREATE_NOTE, PROMPT_SYSTEM_FIX_GRAMMAR, PROMPT_SYSTEM_REWRITE_TEMPLATE, PROMPT_SYSTEM_TRANSLATE_TO_LANGUAGE } from './config/app.js';
import Profile from './components/common/Profile.js';
import { MODELS_LIBRARY } from './config/models.js';
import { jsonrepair } from 'jsonrepair';
import Moment from 'react-moment';
import TurndownService from 'turndown';
import Tags from './components/note/Tags.js';
import BackButton from './components/common/BackButton.js';
import { PushPin } from '@phosphor-icons/react';

export default function Note() {

  const auth = useRequireAuth();
  const navigate = useNavigate();
  const props = useParams();
  const { theme } = useContext(ThemeContext);

  // refs
  const refNote = useRef(null);
  const refDirty = useRef(false);
  const refPreviousContent = useRef(null);

  // state
  const [note, setNote] = useState(null);
  const [noteType, setNoteType] = useState(null);
  const [favorite, setPinned] = useState(false);
  const [inferenceModel, setInferenceModel] = useState(null);
  const [loading, setLoading] = useState(true);

  let isPageWide = useMediaQuery('(min-width: 640px)');

  useEffect(() => {
    if (auth && auth.user) {
      if (props === undefined || props.id === undefined) {
        navigate('/notfound');
      } else {
        initialize(props.id);
      }
    } 
  }, [auth]);


  const handleKeyPress = useCallback((event) => {
    if ((event.ctrlKey && event.key === 's') || (event.metaKey && event.key === 's')) {
      event.preventDefault();
      saveNote();
    }
  }, []);

  useEffect(() => {
    // attach the event listener
    document.addEventListener('keydown', handleKeyPress);

    // remove the event listener
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  const initialize = async (id) => {
    dbGetNote(id).then((res) => {
      if (res) {
        setNote(res);
        refNote.current = res;
        setNoteType(res.type);
        setPinned(res.is_pinned);
        setInferenceModel(MODELS_LIBRARY.find(m => m.id === auth.user.notes_model_id));
        setLoading(false);
      } else {
        navigate('/notfound');
      }
    });
  };

  /*
      Save note automatically every 5 seconds
  */

  useEffect(() => {
    const interval = setInterval(() => {
      if (refDirty.current === true) {
        saveNote();
        refDirty.current = false;
      }
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  /*
      Save note
  */

  const saveNote = async () => {
    let _note = refNote.current;
    _note.updated_at = new Date().toISOString();
    let res = await dbUpdateNote(refNote.current.id, _note);
    if (res) {
      displayChangesSaved('Changes autosaved');
      refDirty.current = false;
    } else {
      toast.error('Failed to save changes');
    }
    return res;
  };

  /*
      Display changes saved message
  */

  const displayChangesSaved = (text) => {
    const textElement = document.createElement('div');
    textElement.classList.add('small');
    textElement.textContent = text;
    textElement.style.position = 'fixed';
    textElement.style.top = '20px';
    textElement.style.left = '50%';
    textElement.style.transform = 'translate(-50%, -50%)';
    textElement.style.color = 'var(--gray-9)';
    textElement.style.zIndex = '1000';
    document.body.appendChild(textElement);
    setTimeout(() => {
      textElement.remove();
    }, 3000);
  };

  /*
      Update note content
  */

  const onUpdateContent = async (content) => {
    refNote.current.note = content;
    refDirty.current = true;
  }

  /*
    Update note tags
  */

  const onUpdateTags = async (tags) => {
    refNote.current.tags = tags;
    refDirty.current = true;
  }

  /*
      Update note title
  */

  const onUpdateTitle = async (title) => {
    refNote.current.title = title;
    refDirty.current = true;
  }

  /*
      Update note favorite
  */

  const onPinned = async (favorite) => {
    refNote.current.is_pinned = favorite;
    refDirty.current = true;
  }

  /*
      Update note template
  */

  const onUpdateTemplate = async (template) => {
    refNote.current.template = template;
    refDirty.current = true;
  }

  /*
      Trash note
  */

  const onTrashNote = async () => {
    dbTrashNote(refNote.current.id, auth.user.id).then((res) => {
      if (res) {
        navigate('/home');
      } else {
        toast.error('Failed to move to trash');
      }
    });
  }

  /*
      Change note type
  */

  const onChangeNoteType = async (type) => {
    refNote.current.type = type;
    let res = await saveNote();
    if (res) {
      setNoteType(type);
    } else {
      toast.error('Failed to change note type');
    }
  }

  /*
      Tiptap editor
  */

  const TiptapEditor = (props) => {

    const lowlight = createLowlight(all);
    
    const editor = useEditor({
      extensions: [
        StarterKit.configure({
          draggable: true,
          heading: {
            levels: [1, 2, 3],
          },
          codeBlock: false,
        }),
        Underline,
        Subscript,
        Superscript,
        CharacterCount.configure({
          limit: 100000,
        }),
        Link.configure({
          openOnClick: true,
          autolink: true,
          defaultProtocol: 'https',
          linkOnPaste: false,
          HTMLAttributes: {
            class: 'tiptap-link',
            rel: 'noopener noreferrer',
          },
        }),
        Image.configure({
          inline: true,
          HTMLAttributes: {
            class: 'tiptap-image',
          },
        }),
        Table.configure({
          resizable: true,
          HTMLAttributes: {
            class: 'tiptap-table',
          },
        }),
        TableRow,
        TableCell,
        TableHeader.configure({
          HTMLAttributes: {
            class: 'tiptap-table-header',
          },
        }),
        CodeBlockLowlight.configure({
          lowlight,
          HTMLAttributes: {
            class: 'tiptap-pre',
          },
        }),
        TaskList,
        TaskItem.configure({
          nested: true,
        }),
      ],
      editorProps: {
        attributes: {
          class: 'prose prose-sm sm:prose lg:prose-lg xl:prose-2xl mx-auto focus:outline-none',
        },
        transformPastedText(text) {
          return text
        },
      },
      onUpdate({ editor }) {
        props.onUpdateContent(editor.getHTML());
      },
      content: props.noteContent,
    })

    const setLink = useCallback(() => {
      const previousUrl = editor.getAttributes('link').href
      const url = window.prompt('Enter a URL', previousUrl)

      // cancelled
      if (url === null) {
        return
      }

      // empty
      if (url === '') {
        editor.chain().focus().extendMarkRange('link').unsetLink()
          .run()

        return
      }

      // update link
      editor.chain().focus().extendMarkRange('link').setLink({ href: url })
        .run()
    }, [editor])

    const addImage = useCallback(() => {
      const url = window.prompt('Enter an image URL')

      if (url) {
        editor.chain().focus().setImage({ src: url }).run()
      }
    }, [editor]);

    /*
      Trash note
    */

    const trashNote = () => {
      props.onTrashNote();
    }

    /*
      Revert to previous version
    */

    const revertToPreviousVersion = () => {
      editor.commands.setContent(refPreviousContent.current);
      props.onUpdateContent(refPreviousContent.current);
      toast.dismiss();
      refPreviousContent.current = null;
    }

    /*
      Update note into a different template
    */

    const rewriteTemplate = async (template) => {
      
      let noteContent = editor.getHTML();
      refPreviousContent.current = noteContent;
      toast.loading('Rewriting to ' + template + ' template...');

      // console.log("system prompt", PROMPT_SYSTEM_REWRITE_TEMPLATE.replace('{format}', NOTE_TEMPLATES.find(t => t.name === template).label) + NOTE_TEMPLATES.find(t => t.name === template).format_instructions + ' Here is a sample of the note format: ' + NOTE_TEMPLATES.find(t => t.name === template).sample);

      // console.log("user prompt", 'Rewrite this note in the ' + NOTE_TEMPLATES.find(t => t.name === template).label + ' format: ' + noteContent);

      let responseLLM = await fetch(process.env.REACT_APP_RUN_VERBANOTES_LOCALLY ? props.inferenceModel.testChatApi : props.inferenceModel.prodChatApi, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${process.env.REACT_APP_API_AUTHORIZATION_CODE}`
        },
        body: JSON.stringify({
            model: props.inferenceModel,
            messages: [{ role: "system", content: PROMPT_SYSTEM_REWRITE_TEMPLATE.replace('{format}', NOTE_TEMPLATES.find(t => t.name === template).label) + NOTE_TEMPLATES.find(t => t.name === template).format_instructions + ' Here is a sample of the note format: ' + NOTE_TEMPLATES.find(t => t.name === template).sample }, { role: "user", content: 'Rewrite this note in the ' + NOTE_TEMPLATES.find(t => t.name === template).label + ' format: ' + noteContent }],
            user_id: props.userId
        })
      });

      if (responseLLM.status === 200) {
        let responseJson = await responseLLM.json();
        if (responseJson) {
            let repairedJson = jsonrepair(responseJson);
            let content = JSON.parse(repairedJson);
            editor.commands.setContent(content.note);
            props.onUpdateContent(content.note);
            props.onUpdateTemplate(template);
            toast.dismiss();
            toast((t) => (
                <div style={{ textAlign: 'left' }}>
                  <Text size="2" style={{ marginRight: 10 }}>Note rewritten into {template} format! Revert to previous version?</Text>
                  <Button variant="ghost" size="2" onClick={() => revertToPreviousVersion()}>Revert <ArrowCounterClockwise size={12} /></Button>
                </div>
              ),
              {
                duration: 5000
              }
            );
        } else {
          toast.dismiss();
          toast.error('Error rewriting note');
        }
      } else {
        toast.dismiss();
        toast.error('Error rewriting note');
      }

      return;

    }

    /*
      Export note
    */

    const exportNote = async (format, title) => {
      
      if (format === 'markdown') {

        let noteContent = editor.getHTML();
        const turndownService = new TurndownService();
        let markdownContent = turndownService.turndown(noteContent);
        const blob = new Blob(['# ' + title + '\n\n' + markdownContent], { type: 'text/markdown' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = title.replace(/\s+/g, '-').toLowerCase() + '.md';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      
      } else {
        toast.error('Error exporting note');
      }

      return;
    }

    /*
      Proof read note
    */

    const proofRead = async () => {
      
      let noteContent = editor.getHTML();
      refPreviousContent.current = noteContent;

      toast.loading('Proofreading...');

      let responseLLM = await fetch(process.env.REACT_APP_RUN_VERBANOTES_LOCALLY ? props.inferenceModel.testChatApi : props.inferenceModel.prodChatApi, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${process.env.REACT_APP_API_AUTHORIZATION_CODE}`
        },
        body: JSON.stringify({
          model: props.inferenceModel,
          messages: [{ role: "system", content: PROMPT_SYSTEM_FIX_GRAMMAR }, { role: "user", content: 'Fix the grammar of this note: ' + noteContent }],
          user_id: props.userId
        })
      });

      if (responseLLM.status === 200) {
        let responseJson = await responseLLM.json();
        if (responseJson) {
            let repairedJson = jsonrepair(responseJson);
            let content = JSON.parse(repairedJson);
            editor.commands.setContent(content.note);
            props.onUpdateContent(content.note);
            toast.dismiss();
            toast((t) => (
              <div style={{ textAlign: 'left' }}>
                <Text size="2" style={{ marginRight: 10 }}>Grammar fixed! Revert to previous version?</Text>
                <Button variant="ghost" size="2" onClick={() => revertToPreviousVersion()}>Revert <ArrowCounterClockwise size={12} /></Button>
              </div>
            ),
            {
              duration: 5000
            }
          );
        } else {
          toast.dismiss();
          toast.error('Error fixing grammar');
        }
      } else {
        toast.dismiss();
        toast.error('Error fixing grammar');
      }

      return;

    }

    /*
      Translate note
    */

    const translateNote = async (to) => {

      let noteContent = editor.getHTML();
      refPreviousContent.current = noteContent;

      toast.loading('Translating to ' + to + '...');

      let responseLLM = await fetch(process.env.REACT_APP_RUN_VERBANOTES_LOCALLY ? props.inferenceModel.testChatApi : props.inferenceModel.prodChatApi, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${process.env.REACT_APP_API_AUTHORIZATION_CODE}`
        },
        body: JSON.stringify({
          model: props.inferenceModel,
          messages: [{ role: "system", content: PROMPT_SYSTEM_TRANSLATE_TO_LANGUAGE.replace('{language}', to) }, { role: "user", content: 'Translate this note to ' + to + ': ' + noteContent }],
          user_id: props.userId
        })
      });

      if (responseLLM.status === 200) {
        let responseJson = await responseLLM.json();
        if (responseJson) {
          let repairedJson = jsonrepair(responseJson);
          let content = JSON.parse(repairedJson);
          editor.commands.setContent(content.note);
          props.onUpdateContent(content.note);
          toast.dismiss();
          toast((t) => (
            <div style={{ textAlign: 'left' }}>
              <Text size="2" style={{ marginRight: 10 }}>Note translated to {to}! Revert to previous version?</Text>
              <Button variant="ghost" size="2" onClick={() => revertToPreviousVersion()}>Revert <ArrowCounterClockwise size={12} /></Button>
            </div>
          ),
            {
              duration: 5000
            }
          );
        } else {
          toast.dismiss();
          toast.error('Error translating note');
        }
      } else {
        toast.dismiss();
        toast.error('Error translating note');
      }

      return;

    }

    /*
      Change note reading level
    */

    const changeNoteReadingLevel = async (level) => {

      let noteContent = editor.getHTML();
      refPreviousContent.current = noteContent;

      toast.loading('Changing reading level to ' + level + '...');

      let responseLLM = await fetch(process.env.REACT_APP_RUN_VERBANOTES_LOCALLY ? props.inferenceModel.testChatApi : props.inferenceModel.prodChatApi, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${process.env.REACT_APP_API_AUTHORIZATION_CODE}`
        },
        body: JSON.stringify({
          model: props.inferenceModel,
          messages: [{ role: "system", content: PROMPT_SYSTEM_CHANGE_READING_LEVEL.replace('{level}', level.name).replace('{description}', level.description) }, { role: "user", content: 'Change the reading level of this note: ' + noteContent }],
          user_id: props.userId
        })
      });

      if (responseLLM.status === 200) {
        let responseJson = await responseLLM.json();
        if (responseJson) {
          let repairedJson = jsonrepair(responseJson);
          let content = JSON.parse(repairedJson);
          // console.log(content);
          editor.commands.setContent(content.note);
          props.onUpdateContent(content.note);
          toast.dismiss();
          toast((t) => (
            <div style={{ textAlign: 'left' }}>e
              <Text size="2" style={{ marginRight: 10 }}>Note reading level changed to {level}! Revert to previous version?</Text>
              <Button variant="ghost" size="2" onClick={() => revertToPreviousVersion()}>Revert <ArrowCounterClockwise size={12} /></Button>
            </div>
          ),
            {
              duration: 5000
            }
          );
        } else {
          toast.dismiss();
          toast.error('Error rewriting note');
        }
      } else {
        toast.dismiss();
        toast.error('Error rewriting note');
      }

      return;

    }

    /*
      Regenerate note from transcript
    */

    const regenerateFromTranscript = async () => {

      let originalTranscript = note.transcript;

      refPreviousContent.current = editor.getHTML();

      toast.loading('Regenerating from original transcript...');

      try {

        let responseLLM = await fetch(process.env.REACT_APP_RUN_VERBANOTES_LOCALLY ? props.inferenceModel.testChatApi : props.inferenceModel.prodChatApi, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${process.env.REACT_APP_API_AUTHORIZATION_CODE}`
          },
          body: JSON.stringify({
            model: props.inferenceModel,
            messages: [{ role: "system", content: PROMPT_SYSTEM_CREATE_NOTE + ' ' + NOTE_TEMPLATES.find(t => t.name === note.template).format_instructions + ' Here is a sample of the note format: ' + NOTE_TEMPLATES.find(t => t.name === note.template).sample }, { role: "user", content: 'Transform the following transcription into a note in the ' + auth.user.default_note_language + ' language using a ' + auth.user.default_note_tone + ' tone. TRANSCRIPTION: ' + originalTranscript }],
            user_id: props.userId
          })
        });

        if (responseLLM.status === 200) {
          let responseJson = await responseLLM.json();
          if (responseJson) {
            let repairedJson = jsonrepair(responseJson);
            let content = JSON.parse(repairedJson);
            editor.commands.setContent(content.note);
            props.onUpdateContent(content.note);
            toast.dismiss();
            toast((t) => (
              <div style={{ textAlign: 'left' }}>
                <Text size="2" style={{ marginRight: 10 }}>Note regenerated from original transcript! Revert to previous version?</Text>
                <Button variant="ghost" size="2" onClick={() => revertToPreviousVersion()}>Revert <ArrowCounterClockwise size={12} /></Button>
              </div>
            ),
              {
                duration: 5000
              }
            );
          } else {
            toast.dismiss();
            toast.error('Error regenerating from original transcript');
          }

        } else {
          toast.dismiss();
          toast.error('Error regenerating from original transcript');
        }

      } catch (error) {
        toast.dismiss();
        toast.error('Error regenerating from original transcript');
      }

      return;
    }

    return (
      <>
        {/* Actions */}
        { editor &&
          <div style={{ width: isPageWide ? '60%' : `calc(100% - 40px)`, marginLeft: isPageWide ? '20%' : 20, marginBottom: 5 }}>
            <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 0 }}>

              <div style={{ marginLeft: 0 }}>
                <DropdownMenu.Root>
                  <DropdownMenu.Trigger><Button variant="soft" size="1" color='gray'>Edit <DropdownMenu.TriggerIcon /></Button></DropdownMenu.Trigger>
                  <DropdownMenu.Content>
                    {/* Change template */}
                    <DropdownMenu.Sub>
                      <DropdownMenu.SubTrigger><SquaresFour /> Change template</DropdownMenu.SubTrigger>
                      <DropdownMenu.SubContent>
                        {NOTE_TEMPLATE_CATEGORIES.map((category, index) => {
                          return (
                            <DropdownMenu.Sub key={index}>
                              <DropdownMenu.SubTrigger>{category.label}</DropdownMenu.SubTrigger>
                              <DropdownMenu.SubContent>
                                {NOTE_TEMPLATES.map((template, index_template) => {
                                  if (template.category === category.name) {
                                    return (
                                      <DropdownMenu.Item key={index_template} style={{ cursor: 'pointer' }} onClick={() => rewriteTemplate(template.name)}>{template.label}</DropdownMenu.Item>
                                    )
                                  }
                                  return null;
                                })}
                              </DropdownMenu.SubContent>
                            </DropdownMenu.Sub>
                          )
                        })}
                      </DropdownMenu.SubContent>
                    </DropdownMenu.Sub>
                    {/* Translate */}
                    <DropdownMenu.Sub>
                      <DropdownMenu.SubTrigger><Translate /> Translate</DropdownMenu.SubTrigger>
                      <DropdownMenu.SubContent>
                        {NOTE_LANGUAGES.map((language, index) => (
                          <DropdownMenu.Item key={index} style={{ cursor: 'pointer' }} onClick={() => translateNote(language)}>{language.charAt(0).toUpperCase() + language.slice(1)}</DropdownMenu.Item>
                          ))}
                      </DropdownMenu.SubContent>
                    </DropdownMenu.Sub>
                    {/* <DropdownMenu.Sub>
                      <DropdownMenu.SubTrigger>Reading level</DropdownMenu.SubTrigger>
                      <DropdownMenu.SubContent>
                        <DropdownMenu.Item style={{ cursor: 'pointer' }} onClick={() => changeNoteReadingLevel('easy')}>5th Grade (Easy)</DropdownMenu.Item>
                        <DropdownMenu.Item style={{ cursor: 'pointer' }} onClick={() => changeNoteReadingLevel('moderate')}>8th Grade (Moderate)</DropdownMenu.Item>
                        <DropdownMenu.Item style={{ cursor: 'pointer' }} onClick={() => changeNoteReadingLevel('difficult')}>12th Grade (Difficult)</DropdownMenu.Item>
                        <DropdownMenu.Item style={{ cursor: 'pointer' }} onClick={() => changeNoteReadingLevel('college')}>College (Very Difficult)</DropdownMenu.Item>
                      </DropdownMenu.SubContent>
                    </DropdownMenu.Sub> */}
                    {/* Turn into  */}
                    {/* <DropdownMenu.Sub>
                      <DropdownMenu.SubTrigger><Translate /> Turn into</DropdownMenu.SubTrigger>
                      <DropdownMenu.SubContent>
                        {DOCUMENT_TYPES.map((type, index) => (
                          <DropdownMenu.Item key={index} style={{ cursor: 'pointer' }} onClick={() => turnNoteInto(type)}>{type.charAt(0).toUpperCase() + type.slice(1)}</DropdownMenu.Item>
                        ))}
                      </DropdownMenu.SubContent>
                    </DropdownMenu.Sub> */}
                    {/* Proofread */}
                    <DropdownMenu.Item style={{ cursor: 'pointer' }} onClick={() => proofRead()}><SealCheck /> Proofread</DropdownMenu.Item>
                    { note.transcript && note.transcript.length > 10 &&
                      <DropdownMenu.Item style={{ cursor: 'pointer' }} onClick={() => regenerateFromTranscript()}><ArrowsClockwise /> Regenerate</DropdownMenu.Item>
                    }
                    <DropdownMenu.Separator />
                    {/* Pin */}
                    <DropdownMenu.Item style={{ cursor: 'pointer' }} onClick={() => { setPinned(!favorite); onPinned(!favorite); }}><PushPin /> { favorite ? 'Remove pin' : 'Add a pin' }</DropdownMenu.Item>
                    {/* Trash */}
                    <DropdownMenu.Item style={{ cursor: 'pointer' }} color="red" onClick={() => trashNote()}><TrashIcon /> Move to trash</DropdownMenu.Item>
                  </DropdownMenu.Content>
                </DropdownMenu.Root>
              </div>
              <div style={{ marginLeft: 10 }}>
                <DropdownMenu.Root>
                  <DropdownMenu.Trigger><Button variant="soft" color='gray' size="1">Export <DropdownMenu.TriggerIcon /></Button></DropdownMenu.Trigger>
                  <DropdownMenu.Content>
                    <DropdownMenu.Item style={{ cursor: 'pointer' }} onClick={() => exportNote('markdown', note.title)}><MarkdownLogo /> Markdown</DropdownMenu.Item>
                  </DropdownMenu.Content>
                </DropdownMenu.Root>
              </div>
            </Row>
          </div>
        }

        <div style={{ height: '100%', paddingLeft: isPageWide ? '20%' : 20, paddingRight: isPageWide ? '20%' : 20, overflowY: 'auto', marginTop: 20, paddingBottom: 300 }}>

          {/* Editor */}
          <EditorContent editor={editor} />

          {/* Bubble menu */}
          {editor && <BubbleMenu className="bubble-menu" tippyOptions={{ duration: 100, maxWidth: "100vw", placement: "bottom" }} editor={editor}>
            <button onClick={() => editor.chain().focus().toggleBold().run()} className={editor.isActive('bold') ? 'btn-tiptap-bubble-menu-active' : 'btn-tiptap-bubble-menu'}>
              <FontBoldIcon size={14} />
            </button>
            <button onClick={() => editor.chain().focus().toggleItalic().run()} className={editor.isActive('italic') ? 'btn-tiptap-bubble-menu-active' : 'btn-tiptap-bubble-menu'}>
              <FontItalicIcon size={14} />
            </button>
            <button
              onClick={() => editor.chain().focus().toggleUnderline().run()}
              className={editor.isActive('underline') ? 'btn-tiptap-bubble-menu-active' : 'btn-tiptap-bubble-menu'}
            >
              <UnderlineIcon size={14} />
            </button>
            <button
              onClick={() => editor.chain().focus().toggleStrike().run()}
              className={editor.isActive('strike') ? 'btn-tiptap-bubble-menu-active' : 'btn-tiptap-bubble-menu'}
            >
              <TextStrikethrough size={14} />
            </button>
            <button
              onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
              className={editor.isActive('heading', { level: 1 }) ? 'btn-tiptap-bubble-menu-active' : 'btn-tiptap-bubble-menu'}
            >
              <TextHOne size={14} />
            </button>
            <button
              onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
              className={editor.isActive('heading', { level: 2 }) ? 'btn-tiptap-bubble-menu-active' : 'btn-tiptap-bubble-menu'}
            >
              <TextHTwo size={14} />
            </button>
            <button
              onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
              className={editor.isActive('heading', { level: 3 }) ? 'btn-tiptap-bubble-menu-active' : 'btn-tiptap-bubble-menu'}
            >
              <TextHThree size={14} />
            </button>
            <button
              onClick={() => editor.chain().focus().toggleBlockquote().run()}
              className={editor.isActive('blockquote') ? 'btn-tiptap-bubble-menu-active' : 'btn-tiptap-bubble-menu'}
            >
              <QuoteIcon />
            </button>
            <button
              onClick={() => editor.chain().focus().unsetAllMarks().run()}
              className={'btn-tiptap-bubble-menu'}
            >
              <TextTSlash size={14} />
            </button>
            <button
              onClick={() => editor.chain().focus().setParagraph().run()}
              className={'btn-tiptap-bubble-menu'}
            >
              <PilcrowIcon />
            </button>
            <button
              onClick={() => editor.chain().focus().toggleBulletList().run()}
              className={editor.isActive('bulletList') ? 'btn-tiptap-bubble-menu-active' : 'btn-tiptap-bubble-menu'}
            >
              <ListBullets size={14} />
            </button>
            <button
              onClick={() => editor.chain().focus().toggleOrderedList().run()}
              className={editor.isActive('orderedList') ? 'btn-tiptap-bubble-menu-active' : 'btn-tiptap-bubble-menu'}
            >
              <ListNumbers size={14} />
            </button>
            <button
              onClick={() => editor.chain().focus().toggleTaskList().run()}
              className={editor.isActive('taskList') ? 'btn-tiptap-bubble-menu-active' : 'btn-tiptap-bubble-menu'}
            >
              <ListChecks size={14} />
            </button>
            <button
              onClick={() => editor.chain().focus().toggleCodeBlock().run()}
              className={editor.isActive('codeBlock') ? 'btn-tiptap-bubble-menu-active' : 'btn-tiptap-bubble-menu'}
            >
              <CodeSimple size={14} />
            </button>

            <button
              onClick={setLink}
              className={editor.isActive('link') ? 'btn-tiptap-bubble-menu-active' : 'btn-tiptap-bubble-menu'}
            >
              <LinkSimple size={14} />
            </button>

            {/* <button onClick={() => transformText('shorten')} className={'btn-tiptap-bubble-menu'}>
              <ArrowsInLineHorizontal size={14} style={{ marginBottom: 2 }} /> Shorten
            </button> */}

            {/* <button onClick={() => transformText('proofread')} className={'btn-tiptap-bubble-menu'}>
              <SealCheck size={14} style={{ marginBottom: 2 }} /> Proofread
            </button> */}

          </BubbleMenu>}

          {editor && <FloatingMenu editor={editor} tippyOptions={{ duration: 100, maxWidth: "100vw", placement: "top" }}>
            <div className="bubble-menu">
              <button
                onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
                className={editor.isActive('heading', { level: 1 }) ? 'btn-tiptap-bubble-menu-active' : 'btn-tiptap-bubble-menu'}
              >
                <TextHOne size={14} />
              </button>
              <button
                onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
                className={editor.isActive('heading', { level: 2 }) ? 'btn-tiptap-bubble-menu-active' : 'btn-tiptap-bubble-menu'}
              >
                <TextHTwo size={14} />
              </button>
              <button
                onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
                className={editor.isActive('heading', { level: 3 }) ? 'btn-tiptap-bubble-menu-active' : 'btn-tiptap-bubble-menu'}
              >
                <TextHThree size={14} />
              </button>
              <button
                onClick={() => editor.chain().focus().toggleBulletList().run()}
                className={editor.isActive('bulletList') ? 'btn-tiptap-bubble-menu-active' : 'btn-tiptap-bubble-menu'}
              >
                <ListBullets size={14} />
              </button>
              <button
                onClick={() => editor.chain().focus().toggleOrderedList().run()}
                className={editor.isActive('orderedList') ? 'btn-tiptap-bubble-menu-active' : 'btn-tiptap-bubble-menu'}
              >
                <ListNumbers size={14} />
              </button>
              <button
                onClick={() => editor.chain().focus().toggleTaskList().run()}
                className={editor.isActive('taskList') ? 'btn-tiptap-bubble-menu-active' : 'btn-tiptap-bubble-menu'}
              >
                <ListChecks size={14} />
              </button>
            </div>
          </FloatingMenu>}

          {/* Character count */}
          <Row style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 60, paddingTop: 5, borderTop: '1px solid var(--gray-4)' }}>
            <Text size="1" color='gray' style={{ marginLeft: 5, marginRight: 5 }}>{editor ? editor.storage.characterCount.characters() : '0'} characters</Text>
            <Text size="1" color='gray' style={{ marginLeft: 5, marginRight: 5 }}>Ctrl+S to save</Text>
          </Row>
        </div>
      </>
    )

  }

  if (!auth || !auth.user || loading || !note || !inferenceModel) {
    return (
      <Row style={{ justifyContent: 'center', marginTop: 20, marginBottom: 40 }}>
        <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, height: '80vh' }}>
          <Spinner size="2" />
        </Row>
      </Row>
    )
  }

  return (
    <div style={{ position: 'fixed', top: 0, width: '100vw', height: `calc(100% - 100px)` }}>
      <BackButton />
      <Profile />
      <div style={{ width: isPageWide ? '80%' : `calc(100% - 40px)`, marginTop: 55, marginLeft: isPageWide ? '20%' : 20 }}>
        <Row style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start', marginLeft: 0, marginRight: 0, marginTop: 0 }}>
          <div style={{ width: `calc(100% - 30px)` }}>
            <Title initTitle={note.title} onUpdateTitle={onUpdateTitle} />
            <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 5, marginBottom: 5 }}>
              <PushPin weight={note.is_pinned ? 'fill' : 'regular'} size={12} color={note.is_pinned ? 'var(--accent-11)' : 'var(--gray-9)'} style={{ cursor: 'pointer', marginRight: 10 }} onClick={() => { setPinned(!favorite); onPinned(!favorite); }} />
              <Text size="1" color="gray"><Moment date={Date.parse(note.created_at)} format="ddd, MMM DD yyyy HH:mm A" /></Text>
              { note.transcript &&
                <AlertDialog.Root>
                  <AlertDialog.Trigger style={{ marginLeft: 10 }}>
                    <Text size="1" color="gray">Show transcript</Text>
                  </AlertDialog.Trigger>
                  <AlertDialog.Content maxWidth="600px" maxHeight="600px">
                    <Row style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 0 }}>
                      <div style={{ width: `calc(100% - 20px)` }}>
                        <AlertDialog.Title style={{ marginBottom: -10 }}>Original transcript</AlertDialog.Title>
                      </div>
                      <div style={{ width: 20 }}>
                        <AlertDialog.Cancel>
                          <X size={20} weight="regular" color="var(--gray-9)" style={{ cursor: 'pointer' }} />
                        </AlertDialog.Cancel>
                      </div>
                    </Row>
                    <VisuallyHidden>
                      <AlertDialog.Description>Transcript</AlertDialog.Description>
                    </VisuallyHidden>
                    <div style={{ marginTop: 20 }}>
                      <Text size="1" color="gray" as="p">{note.transcript}</Text>
                    </div>
                    <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 20 }}>
                      <AlertDialog.Cancel>
                      <Button variant="soft" size="1">Close</Button>
                    </AlertDialog.Cancel>
                  </Row>
                  </AlertDialog.Content>
                </AlertDialog.Root>
              }
            </Row>  
            <Tags tags={note.tags} onUpdateTags={onUpdateTags} />
          </div>
        </Row>
      </div>
      <div style={{ marginTop: 10, height: '100%' }}>
        <TiptapEditor noteContent={note.note} noteFormat={note.format} inferenceModel={inferenceModel} userId={auth.user.id} onUpdateContent={onUpdateContent} onUpdateTemplate={onUpdateTemplate} onTrashNote={onTrashNote} onChangeNoteType={onChangeNoteType} />
      </div>
      <Toaster position='bottom-right' toastOptions={{ className: 'toast', style: { background: 'var(--gray-3)', color: 'var(--gray-11)' } }} />
    </div>
  )


}


