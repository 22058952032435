import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "../../shared-functions";
import { Row } from "react-bootstrap";
import Moment from "react-moment";
import { DotsVerticalIcon, Pencil1Icon, TrashIcon } from "@radix-ui/react-icons";
import { Microphone, PushPin, Warning } from "@phosphor-icons/react";
import { DropdownMenu, Heading, IconButton, Spinner, Text } from "@radix-ui/themes";
import { storageGetSignedUrl } from "../../utilities/storage";
import { transcribeAudioFile } from "../../utilities/assembly";
import { dbUpdateNote } from "../../utilities/sqldb";
import { NOTE_TEMPLATES, PROMPT_SYSTEM_CREATE_NOTE } from "../../config/app";
import { jsonrepair } from "jsonrepair";

const Note = (props) => {

  const navigate = useNavigate();
  const isPageWide = useMediaQuery('(min-width: 640px)');

  const [note, setNote] = useState(null);
  const [favorite, setPinned] = useState(false);

  useEffect(() => {
    setNote(props.note);
    setPinned(props.note.is_pinned || false);
  }, [props]);  

  if (note === null) {
    return null;
  }

  return (
    <div style={{ width: isPageWide ? '60%' : `calc(100% - 16px)`, marginLeft: isPageWide ? `calc(20% - 32px)` : 0 }}>
      <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', marginLeft: 0, marginRight: 0, marginTop: 12, paddingBottom: 10, borderBottom: '1px solid var(--gray-a4)' }}>
        <div style={{ width: 20, marginTop: -5 }}>
          <PushPin weight={favorite ? 'fill' : 'regular'} size={14} color={favorite ? 'var(--accent-11)' : 'var(--gray-9)'} style={{ cursor: 'pointer' }} onClick={() => { setPinned(!favorite); props.onPinned(note.id, !favorite); }} />
        </div>
        <div style={{ width: `calc(100% - 48px)` }}>
          <Heading size='1' style={{ marginBottom: 0, cursor: 'pointer' }} onClick={() => navigate('/note/' + note.id)}>{note.title}</Heading>
          <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 0 }}>
            <Text color='gray' size='1' align='left' style={{ marginRight: 10 }}><Moment date={note.created_at} fromNow /></Text>
            {note.tags.map((tag, index) => (
              <Text key={index} size='1' color="gray" style={{ marginRight: 5 }}>#{tag}</Text>
            ))}
          </Row>
        </div>
        <div style={{ width: 28, textAlign: 'right' }}>
          <DropdownMenu.Root>
            <DropdownMenu.Trigger>
              <IconButton size='1' variant="ghost" color='gray'>
                <DotsVerticalIcon />
              </IconButton>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content>
              <DropdownMenu.Item style={{ cursor: 'pointer' }} onClick={() => navigate('/note/' + note.id)}><Pencil1Icon /> Edit</DropdownMenu.Item>
              <DropdownMenu.Item style={{ cursor: 'pointer' }} onClick={() => { setPinned(!favorite); props.onPinned(note.id, !favorite); }}><PushPin /> {favorite ? 'Remove pin' : 'Add a pin'}</DropdownMenu.Item>
              <DropdownMenu.Item color="red" style={{ cursor: 'pointer' }} onClick={() => props.onTrashNote(note.id)}><TrashIcon /> Move to trash</DropdownMenu.Item>
            </DropdownMenu.Content>
          </DropdownMenu.Root>
        </div>
      </Row>
    </div>
  )
}

export default Note;