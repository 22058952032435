
export const WORKSPACE_MODELS = [
  {
      model_id: 'inference_model_id',
      title: "Inference",
      description: "The LLM model that agents use to generate and summarize content.",
      json_models_only: false
  },
  {
      model_id: 'tools_model_id',
      title: "Tools",
      description: "The LLM model that agents use to execute tool actions.",
      json_models_only: true
  }
];


export const MODELS_LIBRARY = [
  {
    id: 1,
    type: 'Chat',
    provider: 'OpenAI',
    apiProvider: 'OpenAI',
    model: "GPT-4o",
    variant: "gpt-4o",
    summary: "OpenAI's new flagship model.",
    sourceCode: 'Closed',
    parameters: 0,
    context: 128000,
    pricePerInputTokens: 5,
    pricePerOutputTokens: 15,
    jsonMode: true,
    iconLight: '/assets/icons/openai-light.svg',
    iconDark: '/assets/icons/openai-dark.svg',
    testStreamingApi: 'http://127.0.0.1:5001/verbanotes/us-central1/callStreamingChatCompletionOpenAI',
    prodStreamingApi: 'https://callstreamingchatcompletionopenai-3islx7aima-uc.a.run.app',
    testChatApi: 'http://127.0.0.1:5001/verbanotes/us-central1/callChatCompletionOpenAI',
    prodChatApi: 'https://callchatcompletionopenai-3islx7aima-uc.a.run.app',
  },
  {
    id: 2,
    type: 'Chat',
    provider: 'OpenAI',
    apiProvider: 'OpenAI',
    model: "GPT-4o Mini",
    variant: "gpt-4o-mini",
    summary: "OpenAI's cost-effective, yet powerful language model.",
    sourceCode: 'Closed',
    parameters: 0,
    context: 128000,
    pricePerInputTokens: 0.15,
    pricePerOutputTokens: 0.60,
    jsonMode: true,
    iconLight: '/assets/icons/openai-light.svg',
    iconDark: '/assets/icons/openai-dark.svg',
    testStreamingApi: 'http://127.0.0.1:5001/verbanotes/us-central1/callStreamingChatCompletionOpenAI',
    prodStreamingApi: 'https://callstreamingchatcompletionopenai-3islx7aima-uc.a.run.app',
    testChatApi: 'http://127.0.0.1:5001/verbanotes/us-central1/callChatCompletionOpenAI',
    prodChatApi: 'https://callchatcompletionopenai-3islx7aima-uc.a.run.app',
  },
  // {
  //   id: 2,
  //   type: 'Chat',
  //   provider: 'OpenAI',
  //   apiProvider: 'OpenAI',
  //   model: "GPT-3.5 Turbo",
  //   variant: "gpt-3.5-turbo",
  //   summary: "OpenAI's cost-effective, yet powerful language model.",
  //   sourceCode: 'Closed',
  //   parameters: 0,
  //   context: 16385,
  //   pricePerInputTokens: 0.5,
  //   pricePerOutputTokens: 1.5,
  //   jsonMode: true,
  //   iconLight: '/assets/icons/openai-light.svg',
  //   iconDark: '/assets/icons/openai-dark.svg',
  //   testStreamingApi: 'http://127.0.0.1:5001/verbanotes/us-central1/callStreamingChatCompletionOpenAI',
  //   prodStreamingApi: 'https://callstreamingchatcompletionopenai-3islx7aima-uc.a.run.app',
  //   testChatApi: 'http://127.0.0.1:5001/verbanotes/us-central1/callChatCompletionOpenAI',
  //   prodChatApi: 'https://callchatcompletionopenai-3islx7aima-uc.a.run.app',
  // },
  {
    id: 3,
    type: 'Chat',
    provider: 'Google',
    apiProvider: 'Google',
    model: "Gemini 1.5 Pro",
    variant: "gemini-1.5-pro",
    summary: "Google's mid-size multimodal model that is optimized for a wide-range of reasoning tasks.",
    sourceCode: 'Closed',
    parameters: 0,
    context: 1048576,
    pricePerInputTokens: 3.5,
    pricePerOutputTokens: 10.5,
    jsonMode: true,
    iconLight: '/assets/icons/google-gemini.png',
    iconDark: '/assets/icons/google-gemini.png',
    testStreamingApi: 'http://127.0.0.1:5001/verbanotes/us-central1/callStreamingChatCompletionGoogle',
    prodStreamingApi: 'https://callstreamingchatcompletiongoogle-3islx7aima-uc.a.run.app',
    testChatApi: 'http://127.0.0.1:5001/verbanotes/us-central1/callChatCompletionGoogle',
    prodChatApi: 'https://callchatcompletiongoogle-3islx7aima-uc.a.run.app',
  },
  {
    id: 4,
    type: 'Chat',
    provider: 'Google',
    apiProvider: 'Google',
    model: "Gemini 1.5 Flash",
    variant: "gemini-1.5-flash",
    summary: "Google's fast and versatile performance across a diverse variety of tasks.",
    sourceCode: 'Closed',
    parameters: 0,
    context: 1048576,
    pricePerInputTokens: 0.35,
    pricePerOutputTokens: 1.05,
    jsonMode: true,
    iconLight: '/assets/icons/google-gemini.png',
    iconDark: '/assets/icons/google-gemini.png',
    testStreamingApi: 'http://127.0.0.1:5001/verbanotes/us-central1/callStreamingChatCompletionGoogle',
    prodStreamingApi: 'https://callstreamingchatcompletiongoogle-3islx7aima-uc.a.run.app',
    testChatApi: 'http://127.0.0.1:5001/verbanotes/us-central1/callChatCompletionGoogle',
    prodChatApi: 'https://callchatcompletiongoogle-3islx7aima-uc.a.run.app',
  },
  {
    id: 5,
    type: 'Chat',
    provider: 'Meta',
    apiProvider: 'Together',
    model: "Llama 3 70B Turbo",
    variant: "meta-llama/Meta-Llama-3-70B-Instruct-Turbo", // "meta-llama/Llama-3-70b-chat-hf",
    summary: "Meta's powerful, open-source model with 70 billion parameters.",
    sourceCode: 'Open',
    parameters: 0,
    context: 8192,
    pricePerInputTokens: 0.88,
    pricePerOutputTokens: 0.88,
    jsonMode: false,
    iconLight: '/assets/icons/meta.png',
    iconDark: '/assets/icons/meta.png',
    testStreamingApi: 'http://127.0.0.1:5001/verbanotes/us-central1/callStreamingChatCompletionTogether',
    prodStreamingApi: 'https://callstreamingchatcompletiontogether-3islx7aima-uc.a.run.app',
    testChatApi: 'http://127.0.0.1:5001/verbanotes/us-central1/callChatCompletionTogether',
    prodChatApi: 'https://callchatcompletiontogether-3islx7aima-uc.a.run.app',
  },
  {
    id: 12,
    type: 'Chat',
    provider: 'Meta',
    apiProvider: 'Together',
    model: "Llama 3 8B Lite",
    variant: "meta-llama/Meta-Llama-3-8B-Instruct-Lite",
    summary: "Meta's cost-effective, open-source model with 8 billion parameters.",
    sourceCode: 'Open',
    parameters: 0,
    context: 8192,
    pricePerInputTokens: 0.10,
    pricePerOutputTokens: 0.10,
    jsonMode: false,
    iconLight: '/assets/icons/meta.png',
    iconDark: '/assets/icons/meta.png',
    testStreamingApi: 'http://127.0.0.1:5001/verbanotes/us-central1/callStreamingChatCompletionTogether',
    prodStreamingApi: 'https://callstreamingchatcompletiontogether-3islx7aima-uc.a.run.app',
    testChatApi: 'http://127.0.0.1:5001/verbanotes/us-central1/callChatCompletionTogether',
    prodChatApi: 'https://callchatcompletiontogether-3islx7aima-uc.a.run.app',
  },
  {
    id: 6,
    type: 'Chat',
    provider: 'Mistral',
    apiProvider: 'Fireworks',
    model: "Mixtral 3 8x7B",
    variant: "mistralai/Mixtral-8x7B-Instruct-v0.1",
    summary: "Mistral MoE 8x7B Instruct v0.1 model with Sparse Mixture of Experts.",
    sourceCode: 'Open',
    parameters: 0,
    context: 32768,
    pricePerInputTokens: 0.6,
    pricePerOutputTokens: 0.6,
    jsonMode: false,
    iconLight: '/assets/icons/mistral.png',
    iconDark: '/assets/icons/mistral.png',
    testStreamingApi: 'http://127.0.0.1:5001/verbanotes/us-central1/callStreamingChatCompletionTogether',
    prodStreamingApi: 'https://callstreamingchatcompletiontogether-3islx7aima-uc.a.run.app',
    testChatApi: 'http://127.0.0.1:5001/verbanotes/us-central1/callChatCompletionTogether',
    prodChatApi: 'https://callchatcompletiontogether-3islx7aima-uc.a.run.app',
  },
  {
    id: 7,
    type: 'Chat',
    provider: 'Mistral',
    apiProvider: 'Together',
    model: "Mixtral 3 8x22B",
    variant: "mistralai/Mixtral-8x22B-Instruct-v0.1",
    summary: "Mistral MoE 8x22B Instruct v0.1 model with Sparse Mixture of Experts.",
    sourceCode: 'Open',
    parameters: 0,
    context: 65536,
    pricePerInputTokens: 0.9,
    pricePerOutputTokens: 0.9,
    jsonMode: false,
    iconLight: '/assets/icons/mistral.png',
    iconDark: '/assets/icons/mistral.png',
    testStreamingApi: 'http://127.0.0.1:5001/verbanotes/us-central1/callStreamingChatCompletionTogether',
    prodStreamingApi: 'https://callstreamingchatcompletiontogether-3islx7aima-uc.a.run.app',
    testChatApi: 'http://127.0.0.1:5001/verbanotes/us-central1/callChatCompletionTogether',
    prodChatApi: 'https://callchatcompletiontogether-3islx7aima-uc.a.run.app',
  },
  {
    id: 8,
    type: 'Chat',
    provider: 'Anthropic',
    apiProvider: 'Anthropic',
    model: "Claude 3.5 Sonnet",
    variant: "claude-3-5-sonnet-20240620",
    summary: "Anthropic's most intelligent model",
    sourceCode: 'Closed',
    parameters: 0,
    context: 200000,
    pricePerInputTokens: 3.00,
    pricePerOutputTokens: 15.00,
    jsonMode: true,
    iconLight: '/assets/icons/anthropic.png',
    iconDark: '/assets/icons/anthropic.png',
    testStreamingApi: 'http://127.0.0.1:5001/verbanotes/us-central1/callStreamingChatCompletionAnthropic',
    prodStreamingApi: 'https://callstreamingchatcompletionanthropic-3islx7aima-uc.a.run.app',
    testChatApi: 'http://127.0.0.1:5001/verbanotes/us-central1/callChatCompletionAnthropic',
    prodChatApi: 'https://callchatcompletionanthropic-3islx7aima-uc.a.run.app',
  },
  {
    id: 9,
    type: 'Chat',
    provider: 'Anthropic',
    apiProvider: 'Anthropic',
    model: "Claude 3 Haiku",
    variant: "claude-3-haiku-20240307",
    summary: "Anthropic's fast and cost-effective model",
    sourceCode: 'Closed',
    parameters: 0,
    context: 200000,
    pricePerInputTokens: 0.25,
    pricePerOutputTokens: 1.25,
    jsonMode: true,
    iconLight: '/assets/icons/anthropic.png',
    iconDark: '/assets/icons/anthropic.png',
    testStreamingApi: 'http://127.0.0.1:5001/verbanotes/us-central1/callStreamingChatCompletionAnthropic',
    prodStreamingApi: 'https://callstreamingchatcompletionanthropic-3islx7aima-uc.a.run.app',
    testChatApi: 'http://127.0.0.1:5001/verbanotes/us-central1/callChatCompletionAnthropic',
    prodChatApi: 'https://callchatcompletionanthropic-3islx7aima-uc.a.run.app',
  },
  // {
  //   id: 10,
  //   type: 'Code',
  //   provider: 'Meta',
  //   apiProvider: 'Together',
  //   model: "Code Llama Python 70B",
  //   variant: "codellama/CodeLlama-70b-Python-hf",
  //   summary: "Meta's code model for general code synthesis and understanding.",
  //   sourceCode: 'Open',
  //   parameters: 0,
  //   context: 4096,
  //   pricePerInputTokens: 0.9,
  //   pricePerOutputTokens: 0.9,
  //   jsonMode: false,
  //   iconLight: '/assets/icons/meta.png',
  //   iconDark: '/assets/icons/meta.png',
  //   testStreamingApi: 'http://127.0.0.1:5001/verbanotes/us-central1/callStreamingChatCompletionTogether',
  //   prodStreamingApi: 'https://callstreamingchatcompletiontogether-3islx7aima-uc.a.run.app',
  // },
  // {
  //   id: 11,
  //   type: 'Code',
  //   provider: 'Meta',
  //   apiProvider: 'Together',
  //   model: "Code Llama Python 13B",
  //   variant: "codellama/CodeLlama-13b-Python-hf",
  //   summary: "Meta's code model for general code synthesis and understanding.",
  //   sourceCode: 'Open',
  //   parameters: 0,
  //   context: 16384,
  //   pricePerInputTokens: 0.3,
  //   pricePerOutputTokens: 0.3,
  //   jsonMode: false,
  //   iconLight: '/assets/icons/meta.png',
  //   iconDark: '/assets/icons/meta.png',
  //   testStreamingApi: 'http://127.0.0.1:5001/verbanotes/us-central1/callStreamingChatCompletionTogether',
  //   prodStreamingApi: 'https://callstreamingchatcompletiontogether-3islx7aima-uc.a.run.app',
  // },
]

