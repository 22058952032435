// Getting started

export const GETTING_STARTED_NOTE_CONTENT = "<h2>Welcome to <strong>Verbanotes</strong>!</h2><p>Recording, transcribing, and transforming your audio meeting notes has never been easier. Just tap on the Record button on the main page, start talking, rambling, or ranting, and let Verbanotes' voice AI transcribe your speech into formatted notes. </p><p></p><p>Capture your team meetings, standup, sales calls, board meetings, events and more - so you don't miss any detail, all while customizing the template, tone and even language.</p><p></p><p>Here’s how to get started -</p><p></p><h2>Step 1: Set up your preferences</h2><ol><li><p>Click on your avatar in the top-right corner of this page</p></li><li><p>Click on Settings</p></li><li><p>Choose the AI language models of your choice (e.g. Gemini, Anthropic, OpenAI)</p></li><li><p>Choose the default template, tone, and language for your transcriptions</p></li></ol><p></p><h2>Step 2: Record your first note</h2><ol><li><p>Go to the home screen</p></li><li><p>Click on record</p></li><li><p>Don't worry about being articulate—our AI voice tech will take care of it!</p></li></ol><p></p><h2>Step 3: Review and edit your formatted note</h2><ul><li><p><strong>Change template:</strong> Choose from 20+ preset templates</p></li><li><p><strong>Edit tone:</strong> Choose from 5+ tone presets</p></li><li><p><strong>Translate: </strong>Choose from 5+ languages </p></li><li><p><strong>Proofread: </strong>Fix any grammatical errors</p></li></ul>";


// Note templates

// export const NOTE_TEMPLATES = ['plain', 'meeting', 'bullets', 'job interview', 'standup', 'customer interview', 'product launch', '1 on 1', 'sprint planning', 'sales call', 'board meeting', 'sales review', 'project planning', 'networking', 'customer support', 'customer feedback', 'customer onboarding', 'press conference', 'event', 'pitch' ];

export const DEFAULT_NOTE_TEMPLATE = 'meeting';

// Meeting categories - 
// General - Plain, Meeting, Bullets
// Executive - 1 on 1, All Hands, Strategy Session
// Sales - Sales Call, Sales Review, Sales Planning
// Product - Product Launch, Standup, Sprint Planning, Product Demo
// Customer Success - User Interview, Customer Onboarding, Customer Feedback
// Investor - Board Meeting, Investor Pitch
// Marketing - GTM Planning, Marketing Review, Press Conference
// Hiring - Candidate Interview
// Legal - Legal Review, Legal Meeting
// Engineering - Code Review
// Other - Catchup, Brainstorming, Journal Entry, Networking

export const NOTE_TEMPLATE_CATEGORIES = [
    { name: 'general', label: 'General', description: 'General note templates' }, 
    { name: 'executive', label: 'Executive', description: 'Executive note templates' },
    { name: 'sales', label: 'Sales', description: 'Sales note templates' },
    { name: 'product', label: 'Product', description: 'Product note templates' },
    { name: 'investor', label: 'Investor', description: 'Investor note templates' },
    { name: 'marketing', label: 'Marketing', description: 'Marketing note templates' },
    { name: 'hiring', label: 'Hiring', description: 'Hiring note templates' },
    { name: 'legal', label: 'Legal', description: 'Legal note templates' },
    { name: 'engineering', label: 'Engineering', description: 'Engineering note templates' },
    { name: 'other', label: 'Other', description: 'Other note templates' },
]; 

export const NOTE_TEMPLATES = [
    {
        name: 'plain',
        label: 'Plain',
        category: 'general',
        description: 'A plain note template with just paragraphs',
        format_instructions: 'The note must be formatted in paragraphs with one or more sentences per paragraph, without any headings, subheadings, or sections. You may use lists only if necessary.',
        sample: "<p>Paragraph 1</p><p>Paragraph 2</p><p>...</p>",
    },
    { 
        name: 'meeting',
        label: 'Meeting',
        category: 'general',
        description: 'A meeting notes template with 4 sections: Key Points, Action Items, Questions and Additional Notes',
        format_instructions: 'The note must be formatted in 4 sections: Key Points, Action Items, Questions and Additional Notes. Each section should be a heading followed by a list of bullet points.',
        sample: "<h2>Key Points</h2><ul><li>...</li></ul><h2>Action Items</h2><ul data-type='taskList'><li data-checked='false' data-type='taskItem'><label><input type='checkbox'><span></span></label><div><p>...</p></div></li><li data-checked='false' data-type='taskItem'><label><input type='checkbox'><span></span></label><div><p>...</p></div></li><li data-checked='false' data-type='taskItem'><label><input type='checkbox'><span></span></label><div><p>...</p></div></li></ul><h2>Questions</h2><ul><li>...</li></ul><h2>Additional Notes</h2><ul><li>...</li></ul>",
    },
    {
        name: 'bullets',
        label: 'Bulleted List',
        category: 'general',
        description: 'A bulleted list note template with just bullet points',
        format_instructions: 'The note must be formatted in bullet points only with each bullet point containing one sentence.',
        sample: "<ul><li>...</li><li>...</li><li>...</li></ul>",
    },
    { 
        name: '1-on-1',
        label: '1 on 1',
        category: 'executive',
        description: 'A 1 on 1 meeting notes template with 5 sections: Key Points, Wins, Blockers, Feedback, Next Milestones',
        format_instructions: 'The note must be formatted in 5 sections: Key Points, Wins, Blockers, Feedback, Next Milestones. Each section should be a heading followed by a list of bullet points.',
        sample: "<h2>Key Points</h2><ul><li>...</li></ul><h2>Wins</h2><ul><li>...</li></ul><h2>Blockers</h2><ul><li>...</li></ul><h2>Feedback</h2><ul><li>...</li></ul><h2>Next Milestone</h2><ul><li>...</li></ul>",
    },
    {
        name: 'all hands',
        label: 'All Hands',
        category: 'executive',
        description: 'An all hands meeting notes template with 4 sections: Business Overview, Key Initiatives, Team Updates, and Next Steps',
        format_instructions: 'The note must be formatted in 4 sections: Business Overview, Key Initiatives, Team Updates, and Next Steps. Each section should be a heading followed by a list of bullet points.',
        sample: "<h2>Business Overview</h2><ul><li>...</li></ul><h2>Key Initiatives</h2><ul><li>...</li></ul><h2>Team Updates</h2><ul><li>...</li></ul><h2>Next Steps</h2><ul><li>...</li></ul>",
    },
    {
        name: 'strategy session',
        label: 'Strategy Session',
        category: 'executive',
        description: 'A strategy session notes template with 4 sections: Opportunities, Options, Risks, and Action Items',
        format_instructions: 'The note must be formatted in 4 sections: Opportunities, Options, Risks, and Action Items. Each section should be a heading followed by a list of bullet points.',
        sample: "<h2>Opportunities</h2><ul><li>...</li></ul><h2>Options</h2><ul><li>...</li></ul><h2>Risks</h2><ul><li>...</li></ul><h2>Action Items</h2><ul data-type='taskList'><li data-checked='false' data-type='taskItem'><label><input type='checkbox'><span></span></label><div><p>...</p></div></li><li data-checked='false' data-type='taskItem'><label><input type='checkbox'><span></span></label><div><p>...</p></div></li><li data-checked='false' data-type='taskItem'><label><input type='checkbox'><span></span></label><div><p>...</p></div></li></ul>",
    },
    { 
        name: 'sales call',
        label: 'Sales Call',
        category: 'sales',
        description: 'A sales call notes template with 4 sections: Key Points, Client Needs, Objections, and Action Items',
        format_instructions: 'The note must be formatted in 4 sections: Key Points, Client Needs, Objections, and Action Items. Each section should be a heading followed by a list of bullet points.',
        sample: "<h2>Key Points</h2><ul><li>...</li></ul><h2>Client Needs</h2><ul><li>...</li></ul><h2>Objections</h2><ul><li>...</li></ul><h2>Action Items</h2><ul data-type='taskList'><li data-checked='false' data-type='taskItem'><label><input type='checkbox'><span></span></label><div><p>...</p></div></li><li data-checked='false' data-type='taskItem'><label><input type='checkbox'><span></span></label><div><p>...</p></div></li><li data-checked='false' data-type='taskItem'><label><input type='checkbox'><span></span></label><div><p>...</p></div></li></ul>",
    },
    {
        name: 'standup',
        label: 'Standup',
        category: 'product',
        description: 'A standup notes template with 3 sections: Updates, Blockers, and Next Steps',
        format_instructions: 'The note must be formatted in 3 sections: Updates, Blockers, and Next Steps. Each section should be a heading followed by a list of bullet points.',
        sample: "<h2>Updates</h2><ul><li>...</li></ul><h2>Blockers</h2><ul data-type='taskList'><li data-checked='false' data-type='taskItem'><label><input type='checkbox'><span></span></label><div><p>...</p></div></li><li data-checked='false' data-type='taskItem'><label><input type='checkbox'><span></span></label><div><p>...</p></div></li><li data-checked='false' data-type='taskItem'><label><input type='checkbox'><span></span></label><div><p>...</p></div></li></ul><h2>Next Steps</h2><ul data-type='taskList'><li data-checked='false' data-type='taskItem'><label><input type='checkbox'><span></span></label><div><p>...</p></div></li><li data-checked='false' data-type='taskItem'><label><input type='checkbox'><span></span></label><div><p>...</p></div></li><li data-checked='false' data-type='taskItem'><label><input type='checkbox'><span></span></label><div><p>...</p></div></li></ul>",
    },
    { 
        name: 'product demo',
        label: 'Product Demo',
        category: 'product',
        description: 'A product demo notes template with 4 sections: Key Points, Questions, Feedback, and Next Steps',
        format_instructions: 'The note must be formatted in 4 sections: Key Points, Questions, Feedback, and Next Steps. Each section should be a heading followed by a list of bullet points.',
        sample: "<h2>Key Points</h2><ul><li>...</li></ul><h2>Questions</h2><ul><li>...</li></ul><h2>Feedback</h2><ul><li>...</li></ul><h2>Next Steps</h2><ul><li>...</li></ul>",
    },
    {
        name: 'product launch',
        label: 'Product Launch',
        category: 'product',
        description: 'A product launch notes template with 4 sections: Key Points, Questions, Feedback, and Next Steps',
        format_instructions: 'The note must be formatted in 4 sections: Key Points, Questions, Feedback, and Next Steps. Each section should be a heading followed by a list of bullet points.',
        sample: "<h2>Key Points</h2><ul><li>...</li></ul><h2>Questions</h2><ul><li>...</li></ul><h2>Feedback</h2><ul><li>...</li></ul><h2>Next Steps</h2><ul><li>...</li></ul>",
    },
    { 
        name: 'customer interview',
        label: 'Customer Interview',
        category: 'product',
        description: 'A customer interview notes template with 4 sections: Current Usage, Pain Points, Feature Requests, and Next Steps',
        format_instructions: 'The note must be formatted in 4 sections: Current Usage, Pain Points, Feature Requests, and Next Steps. Each section should be a heading followed by a list of bullet points.',
        sample: "<h2>Current Usage</h2><ul><li>...</li></ul><h2>Pain Points</h2><ul><li>...</li></ul><h2>Feature Requests</h2><ul><li>...</li></ul><h2>Next Steps</h2><ul><li>...</li></ul>",
    },
    {
        name: 'investor pitch',
        label: 'Investor Pitch',
        category: 'investor',
        description: 'An investor pitch notes template with 4 sections: Key Points, Questions, Feedback, and Next Steps',
        format_instructions: 'The note must be formatted in 4 sections: Key Points, Questions, Feedback, and Next Steps. Each section should be a heading followed by a list of bullet points.',
        sample: "<h2>Key Points</h2><ul><li>...</li></ul><h2>Questions</h2><ul><li>...</li></ul><h2>Feedback</h2><ul><li>...</li></ul><h2>Next Steps</h2><ul><li>...</li></ul>",
    },
    {
        name: 'board meeting',
        label: 'Board Meeting',
        category: 'investor',
        description: 'A board meeting notes template with 4 sections: Progress Updates, Plans, Challenges, Requests, and Next Steps',
        format_instructions: 'The note must be formatted in 4 sections: Progress Updates, Plans, Challenges, Requests, and Next Steps. Each section should be a heading followed by a list of bullet points.',
        sample: "<h2>Progress Updates</h2><ul><li>...</li></ul><h2>Plans</h2><ul><li>...</li></ul><h2>Challenges</h2><ul><li>...</li></ul><h2>Requests</h2><ul><li>...</li></ul><h2>Next Steps</h2><ul><li>...</li></ul>",
    },
    {
        name: 'user interview',
        label: 'User Interview',
        category: 'product',
        description: 'A user interview notes template with 4 sections: User Background, Current Usage, Pain Points, and Next Steps',
        format_instructions: 'The note must be formatted in 4 sections: User Background, Current Usage, Pain Points, and Next Steps. Each section should be a heading followed by a list of bullet points.',
        sample: "<h2>User Background</h2><ul><li>...</li></ul><h2>Current Usage</h2><ul><li>...</li></ul><h2>Pain Points</h2><ul><li>...</li></ul><h2>Next Steps</h2><ul><li>...</li></ul>",
    },
    {
        name: 'gtm planning',
        label: 'GTM Planning',
        category: 'marketing',
        description: 'A GTM planning notes template with 3 sections: Key Points, Action Items, and Additional Notes',
        format_instructions: 'The note must be formatted in 3 sections: Key Points, Action Items, and Additional Notes. Each section should be a heading followed by a list of bullet points.',
        sample: "<h2>Key Points</h2><ul><li>...</li><li>...</li></ul><h2>Action Items</h2><ul data-type='taskList'><li data-checked='false' data-type='taskItem'><label><input type='checkbox'><span></span></label><div><p>...</p></div></li><li data-checked='false' data-type='taskItem'><label><input type='checkbox'><span></span></label><div><p>...</p></div></li><li data-checked='false' data-type='taskItem'><label><input type='checkbox'><span></span></label><div><p>...</p></div></li></ul><h2>Additional Notes</h2><ul><li>...</li><li>...</li></ul>",
    },
    {
        name: 'candidate interview',
        label: 'Candidate Interview',
        category: 'hiring',
        description: 'A candidate interview notes template with 3 sections: Past Experience, Key Observations, and Next Steps',
        format_instructions: 'The note must be formatted in 3 sections: Past Experience, Key Observations, and Next Steps. Each section should be a heading followed by a list of bullet points.',
        sample: "<h2>Past Experience</h2><ul><li>...</li></ul><h2>Key Observations</h2><ul><li>...</li></ul><h2>Next Steps</h2><ul><li>...</li></ul>",
    },
    { 
        name: 'tech talk',
        label: 'Tech Talk',
        category: 'engineering',
        description: 'A tech talk notes template with 3 sections: Core Concepts, Questions, and Next Steps',
        format_instructions: 'The note must be formatted in 3 sections: Core Concepts, Questions, and Next Steps. Each section should be a heading followed by a list of bullet points.',
        sample: "<h2>Core Concepts</h2><ul><li>...</li></ul><h2>Questions</h2><ul><li>...</li></ul><h2>Next Steps</h2><ul><li>...</li></ul>",
    },
    {
        name: 'demo',
        label: 'Demo',
        category: 'engineering',
        description: 'A demo notes template with 4 sections: Key Points, Benefits, Questions, and Next Steps',
        format_instructions: 'The note must be formatted in 4 sections: Key Points, Benefits, Questions, and Next Steps. Each section should be a heading followed by a list of bullet points.',
        sample: "<h2>Key Points</h2><ul><li>...</li></ul><h2>Benefits</h2><ul><li>...</li></ul><h2>Questions</h2><ul><li>...</li></ul><h2>Next Steps</h2><ul><li>...</li></ul>",
    },
    {
        name: 'legal review',
        label: 'Legal Review',
        category: 'legal',
        description: 'A legal review notes template with 4 sections: Case Summary, Key Issues, Questions, and Next Steps',
        format_instructions: 'The note must be formatted in 4 sections: Case Summary, Key Issues, Questions, and Next Steps. Each section should be a heading followed by a list of bullet points.',
        sample: "<h2>Case Summary</h2><ul><li>...</li></ul><h2>Key Issues</h2><ul><li>...</li></ul><h2>Questions</h2><ul><li>...</li></ul><h2>Next Steps</h2><ul><li>...</li></ul>",
    },
    {
        name: 'press conference',
        label: 'Press Conference',
        category: 'marketing',
        description: 'A press conference notes template with 4 sections: Key Highlights, Questions, Feedback, and Next Steps',
        format_instructions: 'The note must be formatted in 4 sections: Key Highlights, Questions, Feedback, and Next Steps. Each section should be a heading followed by a list of bullet points.',
        sample: "<h2>Key Highlights</h2><ul><li>...</li></ul><h2>Questions</h2><ul><li>...</li></ul><h2>Feedback</h2><ul><li>...</li></ul><h2>Next Steps</h2><ul><li>...</li></ul>",
    },
    {
        name: 'event',
        label: 'Event',
        category: 'other',
        description: 'An event notes template with 3 sections: Key Points, Speakers, and Next Steps',
        format_instructions: 'The note must be formatted in 3 sections: Key Points, Speakers, and Next Steps. Each section should be a heading followed by a list of bullet points.',
        sample: "<h2>Key Points</h2><ul><li>...</li></ul><h2>Speakers</h2><ul><li>...</li></ul><h2>Next Steps</h2><ul><li>...</li></ul>",
    },
    {
        name: 'catch up',
        label: 'Catch Up',
        category: 'other',
        description: 'A catch up notes template with 3 sections: Key Points, Action Items, and Additional Notes',
        format_instructions: 'The note must be formatted in 3 sections: Key Points, Action Items, and Additional Notes. Each section should be a heading followed by a list of bullet points.',
        sample: "<h2>Key Points</h2><ul><li>...</li></ul><h2>Action Items</h2><ul data-type='taskList'><li data-checked='false' data-type='taskItem'><label><input type='checkbox'><span></span></label><div><p>...</p></div></li><li data-checked='false' data-type='taskItem'><label><input type='checkbox'><span></span></label><div><p>...</p></div></li><li data-checked='false' data-type='taskItem'><label><input type='checkbox'><span></span></label><div><p>...</p></div></li></ul><h2>Additional Notes</h2><ul><li>...</li></ul>",
    },
    {
        name: 'journal entry',
        label: 'Journal Entry',
        category: 'other',
        description: 'A journal entry notes template with 4 sections: Today\'s Events, Reflections, Todo Items, and Future Goals',
        format_instructions: 'The note must be formatted in 4 sections: Today\'s Events, Reflections, Todo Items, and Future Goals. Each section should be a heading followed by a list of bullet points.',
        sample: "<h2>Today's Events</h2><ul><li>...</li></ul><h2>Reflections</h2><ul><li>...</li></ul><h2>Todo Items</h2><ul><li>...</li></ul><h2>Future Goals</h2><ul><li>...</li></ul>",
    },
    {
        name: 'networking',
        label: 'Networking',
        category: 'other',
        description: 'A networking notes template with 3 sections: Key Points, Action Items, and Additional Notes',
        format_instructions: 'The note must be formatted in 3 sections: Key Points, Action Items, and Additional Notes. Each section should be a heading followed by a list of bullet points.',
        sample: "<h2>Key Points</h2><ul><li>...</li></ul><h2>Action Items</h2><ul data-type='taskList'><li data-checked='false' data-type='taskItem'><label><input type='checkbox'><span></span></label><div><p>...</p></div></li><li data-checked='false' data-type='taskItem'><label><input type='checkbox'><span></span></label><div><p>...</p></div></li><li data-checked='false' data-type='taskItem'><label><input type='checkbox'><span></span></label><div><p>...</p></div></li></ul><h2>Additional Notes</h2><ul><li>...</li></ul>",
    },
    {
        name: 'brainstorming',
        label: 'Brainstorming',
        category: 'other',
        description: 'A brainstorming notes template with 3 sections: Key Points, Action Items, and Additional Notes',
        format_instructions: 'The note must be formatted in 3 sections: Key Points, Action Items, and Additional Notes. Each section should be a heading followed by a list of bullet points.',
        sample: "<h2>Key Points</h2><ul><li>...</li></ul><h2>Action Items</h2><ul data-type='taskList'><li data-checked='false' data-type='taskItem'><label><input type='checkbox'><span></span></label><div><p>...</p></div></li><li data-checked='false' data-type='taskItem'><label><input type='checkbox'><span></span></label><div><p>...</p></div></li><li data-checked='false' data-type='taskItem'><label><input type='checkbox'><span></span></label><div><p>...</p></div></li></ul><h2>Additional Notes</h2><ul><li>...</li></ul>",
    },
    {
        name: 'planning',
        label: 'Planning',
        category: 'other',
        description: 'A planning notes template with 3 sections: Key Points, Action Items, and Additional Notes',
        format_instructions: 'The note must be formatted in 3 sections: Key Points, Action Items, and Additional Notes. Each section should be a heading followed by a list of bullet points.',
        sample: "<h2>Key Points</h2><ul><li>...</li></ul><h2>Action Items</h2><ul data-type='taskList'><li data-checked='false' data-type='taskItem'><label><input type='checkbox'><span></span></label><div><p>...</p></div></li><li data-checked='false' data-type='taskItem'><label><input type='checkbox'><span></span></label><div><p>...</p></div></li><li data-checked='false' data-type='taskItem'><label><input type='checkbox'><span></span></label><div><p>...</p></div></li></ul><h2>Additional Notes</h2><ul><li>...</li></ul>",
    },

];

// Note tones

export const NOTE_TONES = ['neutral', 'friendly', 'professional', 'casual', 'humorous', 'persuasive', 'thoughtful', 'critical', 'optimistic'];
export const DEFAULT_NOTE_TONE = 'neutral';

// Note languages

export const NOTE_LANGUAGES = ['english', 'spanish', 'french', 'german', 'italian'];
export const DEFAULT_NOTE_LANGUAGE = 'english';

// Create template prompt

export const PROMPT_SYSTEM_CREATE_NOTE = "You are a helpful note taking assistant. You will be given a transcription of a voice recording and you need to rewrite it into an articulate, well-written note along with a title for the note. Keep the language of the note faithful to the transcription given to you, but improve grammar and flow for readability. Do not add extra details or make significant stylistic changes. The goal for you is to write an articulate note that reflects the original transcription accurately. You must return a JSON object with two keys: 'note' and 'title'. The 'note' key should contain the note in a strict HTML format only. The 'title' key should contain the title of the note and must be a string of no more than 60 characters."; 

// Rewrite template prompt

export const PROMPT_SYSTEM_REWRITE_TEMPLATE = "You are a helpful meeting note taking assistant. You will be given a note written in HTML and you will be asked to rewrite it to a {format} format. You must keep the language of the note as close as possible to the original note, avoid adding extra details, but make sure it is grammatically correct and language flows properly in the new format. You must return a JSON object with one key: 'note'. The 'note' key should contain the note in a strict HTML format only.";

// Change tone templates

export const PROMPT_SYSTEM_CHANGE_TONE = "You are a helpful note writing assistant. You will be given a note written in HTML format and you will be asked to rewrite it into a {tone} tone. You must keep the structure of the note exactly the same as the original note, avoid adding extra details, but make sure the language is in a {tone} tone and grammatically correct. You must return a JSON object with one key: 'note'. The 'note' key should contain the note in a strict HTML format only.";

// Fix grammar templates

export const PROMPT_SYSTEM_FIX_GRAMMAR = "You are a helpful note writing assistant. You will be given a note written in HTML format and you will be asked to fix the grammar of the note. You must keep the structure of the note exactly the same as the original note, avoid adding extra details, but make sure the language is grammatically correct. You must return a JSON object with one key: 'note'. The 'note' key should contain the note in a strict HTML format only.";

// Change reading level templates

export const READING_LEVELS = [
    {
        name: '5th Grade',
        description: 'Simple, suitable for children or easy-reading audiences',
    },
    {
        name: '8th Grade',
        description: 'Suitable for general audiences, newspapers, and magazines',
    },
    {
        name: '12th Grade',
        description: 'Suitable for advanced high school students or older teens',
    },
    {
        name: 'College',
        description: 'Suitable for college students or more advanced audiences',
    },
];

export const PROMPT_SYSTEM_CHANGE_READING_LEVEL = "You are a helpful note writing assistant. You will be given a note written in HTML format and you will be asked to rewrite it into the {level} reading level. You must keep the structure of the note exactly the same as the original note, avoid adding extra details, but make sure the language is {description}. You must return a JSON object with one key: 'note'. The 'note' key should contain the note in a strict HTML format only.";

// Translate note to other languages

export const PROMPT_SYSTEM_TRANSLATE_TO_LANGUAGE = "You are a helpful language translation assistant. You will be given a note written in HTML format and you will be asked to rewrite it in {language}. You must keep the structure of the note exactly the same as the original note, avoid adding extra details, but make sure the language is grammatically correct and flows properly in the new language. You must return a JSON object with one key: 'note'. The 'note' key should contain the note in a strict HTML format only.";

// Shorten or proofread text selection

export const PROMPT_SYSTEM_SELECTION_SHORTEN = "You are a helpful text transformation assistant. You will be given text and you will be asked to shorten it. You must return a JSON object with one key: 'text'. The 'text' key should contain the shortened text. Keep the language of the shortened text faithful to the original text, do not add extra details or make significant stylistic changes. Also, the shortened text must be in the exact same format as the original text, including HTML tags.";

export const PROMPT_SYSTEM_SELECTION_PROOFREAD = "You are a helpful text proofreading assistant. You will be given text and you will be asked to proofread it. You must return a JSON object with one key: 'text'. The 'text' key should contain the proofread text. Keep the language of the proofread text faithful to the original text, improve grammar, fix typos and improve flow for readability - but do not add extra details or make significant stylistic changes. Also, the proofread text must be in the exact same format as the original text, including HTML tags.";


// Document types

// export const DOCUMENT_TYPES = ['blog', 'slides', 'tweet', 'tweet thread', 'linkedin post', 'news article', 'email'];

// Turn note into other document types

// export const PROMPT_SYSTEM_TURN_NOTE_INTO_BLOG = "You are a helpful blog writing assistant. You will be given text written in HTML format and you will be asked to rewrite it into a blog post. You must return a JSON object with one key: 'content'. The 'content' key should contain the blog post in a strict HTML format only. The blog post must contain four sections: 1. An introduction section that engages the reader with a brief preview of the topic. 2. The main content section that discusses the topic with appropriate subheadings, paragraphs, lists and bullet points. 3. A conclusion section that summarizes the main points of the blog post along with a key insight. 4. A call to action section that encourages the reader to share the blog post on social media and subscribe to the blog.";

// export const PROMPT_SYSTEM_TURN_NOTE_INTO_SLIDES = "You are a helpful slides writing assistant. You will be given text written in HTML format and you will be asked to rewrite it into content for a sequence of slides. You must return a JSON object with one key: 'content'. The 'content' key should contain the content for a slide deck in a strict HTML format only. The content must be a sequence of at least 4 and a maximum of 10 slides. Each slide must contain four parts: 1. Title of the slide. 2. Content of the slide with appropriate subheadings, paragraphs, lists and bullet points. and 3. A suggestion for an image or chart to accompany the slide.";

// export const PROMPT_SYSTEM_TURN_NOTE_INTO_TWEET = "You are a helpful tweet writing assistant. You will be given text written in HTML format and you will be asked to rewrite it into a tweet. You must return a JSON object with one key: 'content'. The 'content' key should contain the tweet in a strict HTML format only. The tweet must contain a single paragraph with a maximum of 280 characters.";

// export const PROMPT_SYSTEM_TURN_NOTE_INTO_TWEET_THREAD = "You are a helpful tweet thread writing assistant. You will be given text written in HTML format and you will be asked to rewrite it into a tweet thread. You must return a JSON object with one key: 'content'. The 'content' key should contain the tweet thread in a strict HTML format only. The thread must contain a sequence of tweets, each with a maximum of 280 characters. The thread must be no longer than 6 tweets.";

// export const PROMPT_SYSTEM_TURN_NOTE_INTO_LINKEDIN_POST = "You are a helpful LinkedIn post writing assistant. You will be given text written in HTML format and you will be asked to rewrite it into a LinkedIn post. You must return a JSON object with one key: 'content'. The 'content' key should contain the LinkedIn post in a strict HTML format only. The post must begin with a compelling opening sentence that grabs reader's attention. The next sentence should provide a brief context for the main context of the post. Next, it should contain a main body of the post with a clear insight, tip or advice. Do not use headings or lists in the main body. The post should end with a strong closing sentence that could be asking a question, inviting opinions, or prompting readers to share their experiences or tag someone. Make sure the post is no longer than 1000 characters.";

// export const PROMPT_SYSTEM_TURN_NOTE_INTO_NEWS_ARTICLE = "You are a helpful news article writing assistant. You will be given text written in HTML format and you will be asked to rewrite it into a news article. You must return a JSON object with one key: 'content'. The 'content' key should contain the news article in a strict HTML format only. The article must begin with a clear, attention-grabbing headline that is no longer than 100 characters. Next, it should contain an opening sentence that summarizes the who, what, where, when, and why of the news story to quickly engage the reader. Next, it should contain a nut paragraph that explains why the article matters and gives readers a reason to keep reading. Next, include the main body of the article with the most important details, quotes, facts, and any relevant background information. Make sure the article is no longer than 1200 characters. The article should end with a strong closing sentence that offers a final insight and gives readers a sense of closure or next steps.";

// export const PROMPT_SYSTEM_TURN_NOTE_INTO_EMAIL = "You are a helpful email writing assistant. You will be given text written in HTML format and you will be asked to rewrite it into an email. You must return a JSON object with one key: 'content'. The 'content' key should contain the email in a strict HTML format only. The email must begin with a clear, attention-grabbing subject line that is no longer than 50 characters. Next, it should contain a main body of the email with a clear insight, tip and/or advice. The email should end with a strong closing sentence that could be asking a question, inviting opinions, or prompting readers to reply with questions. Make sure the email is no longer than 500 characters.";