import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getMonthYearFromDate, numberWithCommas, useMediaQuery } from './shared-functions.js';
import { Col, Row } from 'react-bootstrap';
import { useRequireAuth } from './use-require-auth.js';
import { Button, Heading, IconButton, Spinner, Text } from '@radix-ui/themes';
import { ArrowLeftIcon, HomeIcon } from '@radix-ui/react-icons';
import Footer from './components/common/Footer.js';
import { MODELS_LIBRARY } from './config/models.js';
import toast, { Toaster } from 'react-hot-toast';
import Profile from './components/common/Profile.js';
import { dbGetUsage } from './utilities/sqldb.js';
import { getLast12Months } from './helpers/datetime.js';
import { CREDITS_MARGIN_MULTIPLIER, TOKEN_TO_CREDIT_MULTIPLIER } from './config/billingplans.js';
import { ThemeContext } from './Theme.js';
import { AgCharts } from 'ag-charts-react';
import BackButton from './components/common/BackButton.js';
import Logo from './components/common/Logo.js';
import SidebarComponent from './components/common/Sidebar.js';

export default function Usage() {

  const auth = useRequireAuth();
  const navigate = useNavigate();
  const theme = useContext(ThemeContext);

  let isPageWide = useMediaQuery('(min-width: 768px)');

  const [model, setModel] = useState(MODELS_LIBRARY[0]);
  const [usageData, setUsageData] = useState([]);
  const [promptCredits, setPromptCredits] = useState(0);
  const [completionCredits, setCompletionCredits] = useState(0);  
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (auth && auth.user) {
      initialize();
    }
  }, [auth]);

  const initialize = async () => {

    // Get usage data
    dbGetUsage(auth.user.id).then((res) => {
      if (res) {
        let last12Months = getLast12Months();
        let _data = [];
        let _promptCredits = 0;
        let _completionCredits = 0;
        last12Months.map((l) => {
          _data.push({ month: l, prompt_credits: 0, completion_credits: 0, total_credits: 0 })
          return null;
        })
        res.map((d) => {
          _data.map((m) => {
            if (m.month === getMonthYearFromDate(d.created_at)) {
              m.prompt_credits += parseInt(d.prompt_tokens) * getInputPricePerToken(d.model_id) * TOKEN_TO_CREDIT_MULTIPLIER * CREDITS_MARGIN_MULTIPLIER;
              m.completion_credits += parseInt(d.completion_tokens) * getOutputPricePerToken(d.model_id) * TOKEN_TO_CREDIT_MULTIPLIER * CREDITS_MARGIN_MULTIPLIER;
              _promptCredits += parseInt(d.prompt_tokens) * getInputPricePerToken(d.model_id) * TOKEN_TO_CREDIT_MULTIPLIER * CREDITS_MARGIN_MULTIPLIER;
              _completionCredits += parseInt(d.completion_tokens) * getOutputPricePerToken(d.model_id) * TOKEN_TO_CREDIT_MULTIPLIER * CREDITS_MARGIN_MULTIPLIER;
              // console.log('prompt_tokens', parseInt(d.prompt_tokens), d.model_id, getInputPricePerToken(d.model_id), parseInt(d.prompt_tokens) * getInputPricePerToken(d.model_id) * TOKEN_TO_CREDIT_MULTIPLIER)
            }
            return null;
          })
          return null;
        })
        setUsageData(_data);
        setPromptCredits(_promptCredits);
        setCompletionCredits(_completionCredits);
      }
      setLoading(false);
    })
  };

  const getInputPricePerToken = (model_id) => {
    let price = 0;
    MODELS_LIBRARY.map((m) => {
      if (m.id === model_id) {
        price = m.pricePerInputTokens / 1000000;
      }
    })
    return price;
  }

  const getOutputPricePerToken = (model_id) => {
    let price = 0;
    MODELS_LIBRARY.map((m) => {
      if (m.id === model_id) {
        price = m.pricePerOutputTokens / 1000000;
      }
    })
    return price;
  };

  const UsageChart = (props) => {

    const [options, setOptions] = useState({
      data: props.data,
      series: [
        { type: 'bar', xKey: 'month', yKey: 'prompt_credits', yName: 'Prompt', stacked: true },
        { type: 'bar', xKey: 'month', yKey: 'completion_credits', yName: 'Completion', stacked: true },
      ],
      axes: [
        {
          type: 'number',
          position: 'left',
          label: {
            formatter: function (params) {
              return numberWithCommas(params.value);
            },
            fontFamily: 'Inter, sans-serif',
          }
        },
        {
          type: 'category',
          position: 'bottom',
        }
      ],
      legend: {
        enabled: true,
      },
      theme: theme.theme === 'dark-theme' ? "ag-financial-dark" : "ag-financial",
      background: {
        visible: false,
      },
      minHeight: 400,
      padding: {
        top: 10,
      }
    });

    return <AgCharts options={options} />;

  };


  if (!auth || !auth.user || loading) {
    return (
      <Row style={{ justifyContent: 'center', marginTop: 20, marginBottom: 40 }}>
        <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, height: '80vh' }}>
          <Spinner size="2" />
        </Row>
      </Row>
    )
  }

  return (
    <div>
      {/* <BackButton /> */}
      <Logo />
      <Profile />
      <SidebarComponent />

      <div style={{ marginLeft: 48, marginTop: 44, width: `calc(100% - 48px)` }}>

        <div style={{ position: 'relative', width: '100%', paddingLeft: isPageWide ? '20%' : '5%', paddingRight: isPageWide ? '20%' : '5%' }}>
          <Heading size="6">Usage</Heading>
        </div>

        <div style={{ position: 'relative', width: '100%', paddingLeft: isPageWide ? '20%' : '5%', paddingRight: isPageWide ? '20%' : '5%', height: '75vh', minHeight: 200, overflowY: 'auto' }}>

          <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', marginLeft: 0, marginRight: 0, marginTop: 0 }}>
            <Col xs={4} sm={4} md={4} lg={4} xl={4} style={{ padding: 0 }}>
              <Text size="1" color="gray">Total Credits</Text>
              <Heading size="6" color="gray">{numberWithCommas(parseFloat(promptCredits + completionCredits).toFixed(2))}</Heading>
            </Col>
          </Row>

          <div style={{ marginTop: 20, width: '100%' }}>
            <UsageChart data={usageData ? usageData : []} />
          </div>

          <Footer />
        </div>

        <Toaster position='bottom-right' toastOptions={{ className: 'toast', style: { background: 'var(--gray-3)', color: 'var(--gray-11)' } }} />

      </div>
    </div>
  )


}


