import { Badge } from '@radix-ui/themes';
import React, { useState, useEffect } from 'react';
import { Row } from 'react-bootstrap';

const Tags = (props) => {

  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    setTags(props.tags);
  }, [props]);

  // Function to handle input change
  const handleInputChange = (e) => {
    const value = e.target.value;
    if (value.includes(',')) {
      const newTags = value.split(',').map(tag => tag.trim()).filter(tag => tag !== '');
      setTags([...tags, ...newTags]);
      setInputValue('');
      props.onUpdateTags([...tags, ...newTags]);
    } else {
      setInputValue(value); // Update input value while typing
    }
  };

  // Function to remove a tag
  const removeTag = (indexToRemove) => {
    setTags(tags.filter((_, index) => index !== indexToRemove));
    props.onUpdateTags(tags.filter((_, index) => index !== indexToRemove));
  };

  // Function to handle key down
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      const value = e.target.value;
      let newTags = [...tags];
      newTags.push(value);
      setTags(newTags);
      setInputValue('');
      props.onUpdateTags(newTags);
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 0 }}>
        {tags.map((tag, index) => (
          <Badge key={index} variant="soft" color="gray" style={{ marginRight: 5 }}>
            #{tag}
            <span style={{ cursor: 'pointer' }} onClick={() => removeTag(index)}>
              &times;
            </span>
          </Badge>
        ))}
        <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          placeholder="Add tags"
          className="tag-input"
        />
      </Row>
    </div>
  );
};

export default Tags;