import { useEffect } from "react"
import { useAuth } from "./use-auth.js"
import { useNavigate } from 'react-router-dom';
import { supabaseClient } from './config/supabase.js';

export function useRequireAuth(redirectUrl = '/'){
  
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {

    // console.log("useRequireAuth called");

    if (auth.user === null) {
      supabaseClient.auth.getSession().then(async({ data: { session } }) => {
        // console.log('Getting session');
        if (!session) {
          // console.log('No session, redirecting to /');
          navigate(redirectUrl);
        } else {
          // console.log('Session found, getting user');
          auth.getOrCreateUser(session);
        }
      })
    }

  }, [auth]);

  return auth;
}
