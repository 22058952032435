import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRequireAuth } from './use-require-auth.js';
import { secondsToHMS, useMediaQuery } from './shared-functions.js';
import { Col, Row } from 'react-bootstrap';
import { ThemeContext } from "./Theme.js";
import { Text, IconButton, DropdownMenu, Spinner, SegmentedControl, Button, TextArea } from '@radix-ui/themes';
import Logo from './components/common/Logo.js';
import { dbCreateNote, dbGetNotes, dbTrashNote, dbUpdateNote } from './utilities/sqldb.js';
import { MODELS_LIBRARY } from './config/models.js';
import toast, { Toaster } from 'react-hot-toast';
import Profile from './components/common/Profile.js';
// import CloudAudioRecorder from './components/home/CloudAudioRecorder.js';
// import CloudAudioStreamRecorder from './components/home/CloudAudioStreamRecorder.js';
import { ArrowsDownUp, Circle, Microphone } from '@phosphor-icons/react';
import { DEFAULT_NOTE_LANGUAGE, DEFAULT_NOTE_TEMPLATE, DEFAULT_NOTE_TONE, NOTE_TEMPLATES, PROMPT_SYSTEM_CREATE_NOTE } from './config/app.js';
import NewNote from './components/home/NewNote.js';
import Note from './components/home/Note.js';
import SearchButton from './components/home/SearchButton.js';
import { v4 as uuidv4 } from 'uuid';
import Footer from './components/common/Footer.js';
import SidebarComponent from './components/common/Sidebar.js';
import { checkSubscriptionStatus } from './utilities/usage.js';
import { jsonrepair } from 'jsonrepair';
// import Import from './components/home/Import.js';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

export default function Home() {

  const auth = useRequireAuth();

  const navigate = useNavigate();
  const { theme } = useContext(ThemeContext);
  let isPageWide = useMediaQuery('(min-width: 640px)');

  const [notes, setNotes] = useState(null);
  const [filter, setFilter] = useState('all');
  const [sort, setSort] = useState('updated_newest');
  const [search, setSearch] = useState('');
  const [inferenceModel, setInferenceModel] = useState(MODELS_LIBRARY[1]);
  const [defaultNoteTemplate, setDefaultNoteTemplate] = useState(DEFAULT_NOTE_TEMPLATE);
  const [defaultNoteTone, setDefaultNoteTone] = useState(DEFAULT_NOTE_TONE);
  const [defaultNoteLanguage, setDefaultNoteLanguage] = useState(DEFAULT_NOTE_LANGUAGE);
  const [subscriptionStatus, setSubscriptionStatus] = useState(false);
  
  const startTimeRef = useRef(null);
  const noteIdRef = useRef(null);
  const transcriptionRef = useRef(null);
  const dirtyRef = useRef(false);
  const [recording, setRecording] = useState(false);

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
    browserSupportsContinuousListening
  } = useSpeechRecognition();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (auth && auth.user) {
      initialize();
    }
  }, [auth]);

  // Initialize notes
  const initialize = async() => {
    const _notes = await dbGetNotes(auth.user.id);
    if (_notes) {
      // setNotes([]);
      setNotes(_notes.sort((a, b) => b.updated_at - a.updated_at));
    }
    if (auth.user.default_note_template) {
      setDefaultNoteTemplate(auth.user.default_note_template);
    }
    if (auth.user.default_note_tone) {
      setDefaultNoteTone(auth.user.default_note_tone);
    }
    if (auth.user.default_note_language) {
      setDefaultNoteLanguage(auth.user.default_note_language);
    }
    // Check subscription status
    checkSubscriptionStatus(auth.user).then((res) => {
      setSubscriptionStatus(res);
    })
    setLoading(false);
  }

  // Trash note
  const onTrashNote = async(noteId) => {
    dbTrashNote(noteId, auth.user.id).then((res) => {
      if (res) {
        let _notes = [];
        notes.map((n) => {
          if (n.id !== noteId) {
            _notes.push(n);
          }
          return null;
        })
        setNotes(_notes.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at)));
        toast.success('Moved to trash');
      } else {
        toast.error('Sorry, could not move to trash.');
      }
    })
  }

  // Pinned note
  const onPinned = async(noteId, favorite) => {
    dbUpdateNote(noteId, { is_pinned: favorite }).then((res) => {
      if (res) {
        toast.success(favorite ? 'Pinned' : 'Removed pin');
        let _notes = [];
        notes.map((n) => {
          if (n.id !== noteId) {
            _notes.push(n);
          } else {
            _notes.push({ ...n, is_pinned: favorite });
          }
          return null;
        })
        setNotes(_notes.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at)));
      }
    })
  }

  // Sort notes
  const onSort = (value) => {
    setSort(value);
    let _notes = notes.sort((a, b) => {
      if (value === 'created_newest') {
        return new Date(b.created_at) - new Date(a.created_at);
      } else if (value === 'created_oldest') {
        return new Date(a.created_at) - new Date(b.created_at);
      } else if (value === 'updated_newest') {
        return new Date(b.updated_at) - new Date(a.updated_at);
      } else if (value === 'updated_oldest') {
        return new Date(a.updated_at) - new Date(b.updated_at);
      } else {
        return 0;
      }
    });
    setNotes(_notes);
  }

  // Start recording
  const startRecording = async() => {

    if (!browserSupportsSpeechRecognition) {
      toast.error('Your browser does not support speech recognition');
      return;
    }

    // Set start time
    startTimeRef.current = Date.now();

    // Reset transcript
    resetTranscript();
    transcriptionRef.current = '';
    
    // Create new note
    let noteId = uuidv4();
    noteIdRef.current = noteId;
    let note = await createNewNote(noteId);

    // Start recording
    setRecording(true);

    // Start speech recognition
    if (browserSupportsContinuousListening) {
      SpeechRecognition.startListening({ continuous: true });
    } else {
      SpeechRecognition.startListening();
    }

  };

  // Stop recording
  const stopRecording = async() => {

    // Stop speech recognition
    SpeechRecognition.stopListening();

    // Set loading to true
    setLoading(true);

    // Set recording to false
    setRecording(false);

    // Transform transcription into a note
    if (transcriptionRef.current && noteIdRef.current) {
      let content = await transformTranscriptionIntoNote(defaultNoteTemplate, transcriptionRef.current);
      if (content) {
        // Update note with LLM result
        let res = await dbUpdateNote(noteIdRef.current, { title: content.title, note: content.note, status: 'ready' });
        if (res) {
          setLoading(false);
          navigate('/note/' + noteIdRef.current);
          reset();
        } else {
          setLoading(false);
          reset();
          toast.error('Sorry, could not save your note');
        }
      } else {
        setLoading(false);
        reset();
        toast.error('Sorry, could not save your note');
      }
    } else {
      setLoading(false);
      reset();
      toast.error('Sorry, could not save your note');
    }

  }

  // Reset
  const reset = () => {
    resetTranscript();
    transcriptionRef.current = '';
    noteIdRef.current = null;
    dirtyRef.current = false;
  }

  // Set transcriptionRef on transcript change
  useEffect(() => {
    if (transcript) {
      transcriptionRef.current = transcript;
      dirtyRef.current = true;
    }
  }, [transcript]);

  // Upload transcript every 5 seconds
  useEffect(() => {
    const interval = setInterval(async () => {
      if (transcriptionRef.current && transcriptionRef.current.length > 0 && noteIdRef.current && dirtyRef.current) {
        let res = await dbUpdateNote(noteIdRef.current, { transcript: transcriptionRef.current });
        if (res) {
          dirtyRef.current = false;
        }
      }
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  // Create new note
  const createNewNote = async(noteId) => {

    let note = await dbCreateNote({
      id: noteId,
      title: 'New note',
      template: defaultNoteTemplate,
      note: '<p>Write something</p>',
      file_path: '',
      tags: [],
      transcript: '',
      status: 'pending',
      created_by: auth.user.id,
      created_at: new Date(),
      updated_at: new Date()
    });

    return note;
    
  }

  // Transform transcription into a note
  const transformTranscriptionIntoNote = async (template, transcript) => {

    try {

      // Call LLM
      let responseLLM = await fetch(process.env.REACT_APP_RUN_VERBANOTES_LOCALLY ? inferenceModel.testChatApi : inferenceModel.prodChatApi, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${process.env.REACT_APP_API_AUTHORIZATION_CODE}`
        },
        body: JSON.stringify({
          model: inferenceModel,
          messages: [{ role: "system", content: PROMPT_SYSTEM_CREATE_NOTE + ' ' + NOTE_TEMPLATES.find(t => t.name === template).format_instructions + ' Here is a sample of the note format: ' + NOTE_TEMPLATES.find(t => t.name === template).sample }, { role: "user", content: 'Transform the following transcription into a note in the ' + defaultNoteLanguage + ' language using a ' + defaultNoteTone + ' tone. TRANSCRIPTION: ' + transcript }],
          user_id: auth.user.id
        })
      });

      if (responseLLM.status === 200) {
        let responseJson = await responseLLM.json();
        if (responseJson) {
          let repairedJson = jsonrepair(responseJson);
          let content = JSON.parse(repairedJson);
          return content;
        } else {
          // console.error('Error parsing LLM response', responseJson);
          return null;
        }
      } else {
        // let errorJson = await responseLLM.json();
        // console.error('Error calling LLM', errorJson);
        return null;
      }

    } catch (error) {
      // console.error('Error transforming transcription into note', error);
      return null;
    }

  }

  // Duration clock
  const DurationClock = () => {

    const [elapsedTime, setElapsedTime] = useState(0);

    useEffect(() => {

      const interval = setInterval(() => {
        setElapsedTime(Date.now() - startTimeRef.current);
      }, 1000);

      return () => clearInterval(interval);

    }, [startTimeRef.current]);

    return (
      <div>
        <Text size='2' style={{ color: 'var(--gray-11)' }}>
          {secondsToHMS(elapsedTime / 1000)}
        </Text>
      </div>
    )
  } 

  if (!auth || !auth.user || loading || !notes) {
    return (
      <div style={{ position: 'fixed', top: 80, width: '100vw', height: `calc(100% - 100px)` }}>
        <Logo navigate={false} />
        <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, height: '80vh' }}>
          <Spinner size="2" />
        </Row>
      </div>
    )
  }

  if (recording) {
    return (
      <div style={{ position: 'fixed', top: 80, width: '100vw', height: `calc(100% - 100px)` }}>
        <Logo navigate={false} />
        <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, height: '80vh' }}>
          <Col xs={11} sm={10} md={9} lg={6} xl={4} xxl={4} style={{ padding: 10, textAlign: 'center' }}>
            <div className="pulsating-icon" style={{ marginBottom: 3 }}>
              <Circle size={20} weight='fill' color='crimson' />
            </div>
            <div style={{ width: '100%', textAlign: 'center', marginTop: 10 }}>
              <DurationClock />
            </div>
            <Button variant="soft" color='gray' size='3' style={{ marginTop: 20 }} onClick={() => stopRecording()}>End recording</Button>
            <div style={{ width: '100%', height: 200, textAlign: 'center', marginTop: 20, overflowY: 'auto', padding: 10 }}>
              { transcript && transcript.length > 0 && (
                <Text size='3' style={{ color: 'var(--gray-11)' }}>{transcript.slice(-100)}</Text>
              )}
              { !transcript || transcript.length === 0 && (
                <Text size='1' style={{ color: 'var(--gray-11)', fontStyle: 'italic' }}>Transcription will appear here</Text>
              )}
            </div>
          </Col>
        </Row>
      </div>
    )
  }

  return (
    <div style={{ width: '100%' }}>

      <Logo navigate={false} />
      <Profile />
      <NewNote userId={auth.user.id} />
      {/* <Import userId={auth.user.id} /> */}
      <SidebarComponent />

      <div style={{ position: 'fixed', top: 7, width: isPageWide ? 160 : 40, marginLeft: isPageWide ? `calc(50% - 80px)` : `calc(50% - 20px)` }}>
        <IconButton size='2' variant="solid" color='red' radius='full' style={{ width: '100%' }} onClick={() => startRecording()}>
          <Microphone size={16} weight='fill' /> {isPageWide && <Text size='2' style={{ marginLeft: 4 }}>Record</Text>}
        </IconButton>
      </div>

      { notes.length === 0 && (
        <div style={{ position: 'fixed', top: 60, width: isPageWide ? `calc(60% - 16px)` : `calc(100% - 64px)`, marginLeft: isPageWide ? '20%' : 48 }}>
          <Text size='2' align='center' color='gray'>No notes</Text>
        </div>
      )}

      { notes.length > 0 &&
         <div style={{ position: 'fixed', top: 60, width: isPageWide ? `calc(60% - 16px)` : `calc(100% - 64px)`, marginLeft: isPageWide ? '20%' : 48 }}>
          <Row style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginLeft: 0, marginRight: 0, marginBottom: 10 }}>
            <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8} style={{ padding: 0, paddingRight: 10 }}>
              <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 5 }}>
                <SegmentedControl.Root defaultValue="all" size="2" onValueChange={(value) => { setFilter(value); }}>
                  <SegmentedControl.Item value="all">All</SegmentedControl.Item>
                  <SegmentedControl.Item value="pinned">Pinned</SegmentedControl.Item>
                </SegmentedControl.Root>
                <DropdownMenu.Root>
                  <DropdownMenu.Trigger style={{ marginLeft: 10 }}>
                    <IconButton size='2' variant="soft" color='gray'>
                      <ArrowsDownUp />
                    </IconButton>
                  </DropdownMenu.Trigger>
                  <DropdownMenu.Content align="start">
                    <DropdownMenu.Item style={{ cursor: 'pointer' }} onClick={() => { onSort('created_newest'); }}>Created date (newest first)</DropdownMenu.Item>
                    <DropdownMenu.Item style={{ cursor: 'pointer' }} onClick={() => { onSort('created_oldest'); }}>Created date (oldest first)</DropdownMenu.Item>
                    <DropdownMenu.Item style={{ cursor: 'pointer' }} onClick={() => { onSort('updated_newest'); }}>Updated date (newest first)</DropdownMenu.Item>
                    <DropdownMenu.Item style={{ cursor: 'pointer' }} onClick={() => { onSort('updated_oldest'); }}>Updated date (oldest first)</DropdownMenu.Item>
                  </DropdownMenu.Content>
                </DropdownMenu.Root>
              </Row>
            </Col>
            <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4} style={{ padding: 0, paddingLeft: 10, textAlign: 'right' }}>
              <SearchButton notes={notes} />
            </Col>
          </Row>
        </div>
      }

      { notes.length > 0 &&
        <div style={{ position: 'fixed', top: 110, width: `calc(100% - 48px)`, marginLeft: 48, height: `calc(100% - 120px)`, paddingBottom: 20 }}>
          <div style={{ height: '100%', overflowY: 'auto' }}>
            {
              notes.map((note, index) => {
                if (filter === 'all' || (filter === 'pinned' && note.is_pinned)) {
                  if (search === '' || note.title.toLowerCase().includes(search.toLowerCase()) || note.tags.join(',').toLowerCase().includes(search.toLowerCase())) {
                    return (
                      <Note key={index} note={note} userId={auth.user.id} inferenceModel={inferenceModel} defaultNoteTemplate={defaultNoteTemplate} defaultNoteTone={defaultNoteTone} defaultNoteLanguage={defaultNoteLanguage} onTrashNote={onTrashNote} onPinned={onPinned} />
                    )
                  }
                }
                return null;
              })
            }
            <Footer />
          </div>
        </div>
      }

      <Toaster position='bottom-right' toastOptions={{ className: 'toast', style: { background: 'var(--gray-3)', color: 'var(--gray-11)' } }} />

    </div>
  )

}

