import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import { ThemeContext } from "../../Theme.js";
import { Avatar } from '@radix-ui/themes';

export default function Logo(props) {

  const navigate = useNavigate();
  const { theme } = useContext(ThemeContext);

  return (
    <div style={{ position: 'fixed', top: 5, left: 5, overflowY: 'hidden' }}>
      <Image src={'/icon.svg'} style={{ width: 36, marginTop: 0, cursor: 'pointer', borderRadius: 5 }} onClick={() => props.navigate ? navigate('/') : null} />
    </div>
  )

}
