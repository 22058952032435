import { supabaseClient } from "../config/supabase";

/*
    Upload file
    Parameters
        id
    Response
        true or false
*/

export const storageUploadFile = async (file, file_path) => {

    const { data, error } = await supabaseClient
      .storage
      .from('audiofiles')
      .upload(file_path, file, {
        cacheControl: '3600',
        upsert: false
      })

    if (error) { 
        return false;
    } else {
        if (data) {
            return true;
        } else {
            return false;
        }
    }

};

/*
    Get signed URL
    Parameters
        file_path
    Response
        Signed URL or null
*/

export const storageGetSignedUrl = async (file_path) => {

    const { data, error } = await supabaseClient
    .storage
    .from('audiofiles')
    .createSignedUrl(file_path, 60)

    if (data) {
        return data.signedUrl;
    } else {
        return null;
    }

};

/*
    Delete file
    Parameters
        id
    Response
        true or false
*/

export const storageDeleteFile = async (file_path) => {

    const { data, error } = await supabaseClient
    .storage
    .from('files')
    .remove([file_path])

    // console.log('delete file', data, error);

    if (error) { 
        return false;
    } else {
        if (data) {
            return true;
        } else {
            return false;
        }
    }

};

