import React, { useState, useContext, createContext } from "react";
import { supabaseClient } from "./config/supabase";
import { dbCreateNote, dbCreateUser, dbGetUser, dbUpdateUser } from "./utilities/sqldb";
import { v4 as uuidv4 } from 'uuid';
import { GETTING_STARTED_NOTE_CONTENT } from "./config/app";
// import { mixPanelTrackEvent } from "./utilities/mixpanel";

const authContext = createContext();

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = () => {
  return useContext(authContext);
};

function useProvideAuth() {

  const [user, setUser] = useState(null);

  const signOut = async () => {
    let res = await supabaseClient.auth.signOut();
    setUser(null);
    localStorage.clear();
    sessionStorage.clear();
  }

  const getOrCreateUser = async (session) => {
    // console.log('getOrCreateUser');
    if (session && session.user) {
      // Get user
      let resUser = await dbGetUser(session.user);
      if (resUser) {
        setUser(resUser);
      } else {
        // Create new user
        let createdUser = await dbCreateUser(session.user);
        if (createdUser) {
          let newUser = await dbGetUser(session.user);
          if (newUser) {
            setUser(newUser);
            // Create getting started note
            let id = uuidv4();
            await dbCreateNote({
              id: id,
              title: 'Getting Started',
              template: 'outline',
              note: GETTING_STARTED_NOTE_CONTENT,
              tags: ['getstarted'],
              created_by: newUser.id
            });

            // Track signup event
            // await mixPanelTrackEvent(newUser.id, newWorkspace.id, 'Sign Up', {
            //   distinct_id: newUser.id,
            // })
          }
        }
      }
    }
  }

  const updateUser = async(_user) => {
    setUser(_user);
    let res = await dbUpdateUser(user.id, _user);
    return res;
  }

  return {
    user,
    signOut,
    getOrCreateUser,
    updateUser
  };

};


