import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, DropdownMenu } from "@radix-ui/themes";
import { NOTE_TEMPLATE_CATEGORIES, NOTE_TEMPLATES } from "../../config/app";
import { v4 as uuidv4 } from 'uuid';
import toast from 'react-hot-toast';
import { dbCreateNote } from '../../utilities/sqldb';
import { PlusCircledIcon } from "@radix-ui/react-icons";
import { useMediaQuery } from "../../shared-functions";

export default function NewNote(props) {

  const navigate = useNavigate();
  let isPageWide = useMediaQuery('(min-width: 640px)');

  const [userId, setUserId] = useState(null);

  useEffect(() => {
    setUserId(props.userId);
  }, [props.userId]);

  // Create blank note
  const createNewNote = async (template) => {

    let _note = {
      id: uuidv4(),
      title: template.label,
      template: template.name,
      note: template.sample,
      tags: [],
      transcript: '',
      status: 'ready',
      created_by: props.userId,
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString()
    }

    dbCreateNote(_note).then((res) => {
      if (res) {
        navigate('/note/' + _note.id);
      } else {
        toast.error('Sorry, could not create a new note');
      }
    });

  }

  if (!userId) {
    return null;
  }

  return (
    <div style={{ position: 'fixed', top: 7, right: 45, zIndex: 1000, overflowY: 'hidden' }}>
      <DropdownMenu.Root>
        <DropdownMenu.Trigger>
          <Button variant="soft" color="gray"><PlusCircledIcon weight="bold" />{isPageWide ? 'New' : ''}<DropdownMenu.TriggerIcon /></Button>
        </DropdownMenu.Trigger>
        <DropdownMenu.Content side="bottom" sideOffset={5} align="center" alignOffset={10}>
          {/* Category */}
          {NOTE_TEMPLATE_CATEGORIES.map((category, index) => {
            return (
              <DropdownMenu.Sub key={index}>
                <DropdownMenu.SubTrigger>{category.label}</DropdownMenu.SubTrigger>
                <DropdownMenu.SubContent>
                  {NOTE_TEMPLATES.map((template, index_template) => {
                    if (template.category === category.name) {
                      return (
                        <DropdownMenu.Item key={index_template} style={{ cursor: 'pointer' }} onClick={() => createNewNote(template)}>{template.label}</DropdownMenu.Item>
                      )
                    }
                    return null;
                  })}
                </DropdownMenu.SubContent>
              </DropdownMenu.Sub> 
            )
          })}
        </DropdownMenu.Content>
      </DropdownMenu.Root>
    </div>
  )

}

