import { CREDITS_MARGIN_MULTIPLIER, TOKEN_TO_CREDIT_MULTIPLIER } from "../config/billingplans";
import { MODELS_LIBRARY } from "../config/models";
import { dbGetUsage } from "./sqldb";

/*
    Check subscription status
*/

const getInputPricePerToken = (model_id) => {
    let price = 0;
    MODELS_LIBRARY.map((m) => {
        if (m.id === model_id) {
            price = m.pricePerInputTokens / 1000000;
        }
        return null;
    })
    return price;
}

const getOutputPricePerToken = (model_id) => {
    let price = 0;
    MODELS_LIBRARY.map((m) => {
        if (m.id === model_id) {
            price = m.pricePerOutputTokens / 1000000;
        }
        return null;
    })
    return price;
}

export const checkSubscriptionStatus = async (user) => {

    if (!user.stripe_customer_id ||
        (user.stripe_customer_id && !user.stripe_plan_id) ||
        (user.stripe_customer_id && user.stripe_plan_id && user.stripe_status !== 'active')
    ) {
        // Free tier
        // Check if total credits is below max limit
        let prompt_credits = 0;
        let completion_credits = 0;
        let usage = await dbGetUsage(user.id);
        if (usage) {
            usage.map((u) => {
                prompt_credits += parseInt(u.prompt_tokens) * getInputPricePerToken(u.model_id) * TOKEN_TO_CREDIT_MULTIPLIER * CREDITS_MARGIN_MULTIPLIER;
                completion_credits += parseInt(u.completion_tokens) * getOutputPricePerToken(u.model_id) * TOKEN_TO_CREDIT_MULTIPLIER * CREDITS_MARGIN_MULTIPLIER;
                return null;
            })
        }
        // console.log('prompt_credits, completion_credits', prompt_credits, completion_credits, prompt_credits + completion_credits);
        if (prompt_credits + completion_credits > 10) {
            return false;
        } else {
            return true;
        }

    } else {
        // Pro tier
        // TODO: Compute credits used since start timestamp of current payment cycle
        return true;

    }

}