import React, { useContext } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import "./App.css";

import { Theme } from "@radix-ui/themes";
import { ThemeContext } from "./Theme.js";
import { Container, Row, Col } from 'react-bootstrap';
import { ProvideAuth } from "./use-auth.js";

import Auth from "./Auth.js";
import NotFound from "./NotFound.js";
import Home from "./Home.js";
import Note from "./Note.js";
import Settings from "./Settings.js";
import Usage from "./Usage.js";
import Billing from "./Billing.js";
import StripePayments from "./StripePayments.js";

export default function App() {

  const { theme } = useContext(ThemeContext);

  document.body.style = 'background: var(--accent-1)';

  return (
    <ProvideAuth>
      <Router>
        <Theme accentColor={ theme === 'dark-theme' ? "yellow" : "yellow" } appearance={ theme === 'dark-theme' ? "dark" : "light" }>
          <Container className={`App ${theme}`} fluid style={{ marginTop: 0, padding: 0, height: '100%' }}>
            <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start', marginTop: 0, marginLeft: 0, marginRight: 0 }}>
              <Col style={{ width: '100%', padding: 0, minHeight: '100vh' }}>
                <Routes>
                  {/* Catch all */}
                  <Route path="/notfound" element={<NotFound />} />
                  <Route path="/note/:id" element={<Note />} />
                  <Route path="/home" element={<Home />} />
                  <Route path="/settings" element={<Settings />} />
                  <Route path="/usage" element={<Usage />} />
                  <Route path="/billing" element={<Billing />} />
                  <Route path="/payments/:status" element={<StripePayments />} />
                  <Route path="/" element={<Auth />} />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </Col>
            </Row>
          </Container>
        </Theme>
      </Router>
    </ProvideAuth>
  );
}
