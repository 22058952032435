import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useMediaQuery } from "../../helpers/dom";

export const Title = (props) => {

  const [title, setTitle] = useState('');

  let isPageWide = useMediaQuery('(min-width: 960px)');

  useEffect(() => {
    setTitle(props.initTitle);
  }, [props]);

  useEffect(() => {
    var text = document.getElementById('title_box');
    if (text) {
      text.style.height = 'auto';
      text.style.height = text.scrollHeight + 'px';
    }
  }, [title, props]);

  return (
    <div style={{ width: '100%' }}>
      <Form.Group>
        <Form.Control
          id="title_box"
          as="textarea"
          type="text"
          minLength="3"
          maxLength="60"
          rows={1}
          value={title}
          style={{ padding: 0, height: '', outline: 'none', resize: 'none', border: 'none', backgroundColor: 'transparent', color: 'var(--text-color)', fontFamily: 'Inter Bold', fontSize: '1.4rem', lineHeight: '1.2', letterSpacing: -0.5, overflowY: 'hidden' }}
          onChange={(e) => {
            let _title = e.target.value.length === 0 ? 'Untitled' : e.target.value.substring(0, 60);
            setTitle(_title);
            props.onUpdateTitle(_title);
          }}
          autoComplete='off'
        />
      </Form.Group>
    </div>
  )
}

