import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from './shared-functions.js';
import { Col, Row } from 'react-bootstrap';
import { useRequireAuth } from './use-require-auth.js';
import { Heading, Select, Spinner, Text } from '@radix-ui/themes';
import Footer from './components/common/Footer.js';
import { MODELS_LIBRARY } from './config/models.js';
import toast, { Toaster } from 'react-hot-toast';
import Profile from './components/common/Profile.js';
import { ThemeContext } from './Theme.js';
import { DEFAULT_NOTE_LANGUAGE, DEFAULT_NOTE_TEMPLATE, DEFAULT_NOTE_TONE, NOTE_LANGUAGES, NOTE_TEMPLATES, NOTE_TONES } from './config/app.js';
import Logo from './components/common/Logo.js';
import SidebarComponent from './components/common/Sidebar.js';

export default function Settings() {

  const auth = useRequireAuth();
  const navigate = useNavigate();
  const theme = useContext(ThemeContext);

  let isPageWide = useMediaQuery('(min-width: 768px)');

  const [model, setModel] = useState(MODELS_LIBRARY[0]);
  const [loading, setLoading] = useState(true);
  const [defaultTemplate, setDefaultTemplate] = useState(NOTE_TEMPLATES.find(t => t.name === DEFAULT_NOTE_TEMPLATE));
  const [defaultTone, setDefaultTone] = useState(DEFAULT_NOTE_TONE);
  const [defaultLanguage, setDefaultLanguage] = useState(DEFAULT_NOTE_LANGUAGE);

  useEffect(() => {
    if (auth && auth.user) {
      initialize();
    }
  }, [auth]);

  const initialize = async () => {
    // Set model
    if (auth.user.notes_model_id) {
      setModel(MODELS_LIBRARY.find(m => m.id === auth.user.notes_model_id));
    }
    // Set default template, tone, and language
    setDefaultTemplate(auth.user.default_note_template);
    setDefaultTone(auth.user.default_note_tone);
    setDefaultLanguage(auth.user.default_note_language);
    setLoading(false);
  };

  const updateModel = async (id) => {
    setModel(MODELS_LIBRARY.find(m => m.id === id));
    let _newUser = auth.user;
    _newUser.notes_model_id = id;
    let res = await auth.updateUser(_newUser);
    if (res) {
      toast.success('Model updated');
    } else {
      toast.error('Error updating model');
    }
  };

  const updateDefaultTemplate = async (template) => {
    setDefaultTemplate(template);
    let _newUser = auth.user;
    _newUser.default_note_template = template;
    let res = await auth.updateUser(_newUser);
    if (res) {
      toast.success('Default template updated');
    } else {
      toast.error('Error updating default template');
    }
  };

  const updateDefaultTone = async (tone) => {
    setDefaultTone(tone);
    let _newUser = auth.user;
    _newUser.default_note_tone = tone;
    let res = await auth.updateUser(_newUser);
    if (res) {
      toast.success('Default tone updated');
    } else {
      toast.error('Error updating default tone');
    }
  };

  const updateDefaultLanguage = async (language) => {
    setDefaultLanguage(language);
    let _newUser = auth.user;
    _newUser.default_note_language = language;
    let res = await auth.updateUser(_newUser);
    if (res) {
      toast.success('Default language updated');
    } else {
      toast.error('Error updating default language');
    }
  };

  if (!auth || !auth.user || loading) {
    return (
      <Row style={{ justifyContent: 'center', marginTop: 20, marginBottom: 40 }}>
        <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, height: '80vh' }}>
          <Spinner size="2" />
        </Row>
      </Row>
    )
  }

  return (
    <div>
      <Logo navigate={true} />
      <Profile />
      <SidebarComponent />

      <div style={{ marginLeft: 48, marginTop: 44, width: `calc(100% - 48px)` }}>

        <div style={{ position: 'relative', width: '100%', paddingLeft: isPageWide ? '20%' : '5%', paddingRight: isPageWide ? '20%' : '5%' }}>
          <Heading size="6">Settings</Heading>
        </div>

        <div style={{ position: 'relative', width: '100%', paddingLeft: isPageWide ? '20%' : '5%', paddingRight: isPageWide ? '20%' : '1%', height: '75vh', minHeight: 200, overflowY: 'auto' }}>
      
          <Row style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start', marginLeft: 0, marginRight: 0, marginTop: 20 }}>
            <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ padding: 0 }}>
              <Heading size="2" color="gray">Model</Heading>
              <Text size="1" weight="light" color="gray" wrap="balance" as="p">Choose the model you want to use by default for note transcriptions and other language processing tasks.</Text>
            </Col>
            <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ paddingLeft: 10, textAlign: 'right' }}>
              <Select.Root defaultValue={model.id} onValueChange={(value) => updateModel(value)}>
                <Select.Trigger variant="solid" size='2' style={{ cursor: 'pointer' }} />
                <Select.Content align="start">
                  {MODELS_LIBRARY.filter(m => m.jsonMode === true).map((m) => (
                    <Select.Item key={m.id} value={m.id} style={{ cursor: 'pointer' }}>{m.model}</Select.Item>
                  ))}
                </Select.Content>
              </Select.Root>
            </Col>
          </Row>

          <Row style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start', marginLeft: 0, marginRight: 0, marginTop: 20 }}>
            <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ padding: 0 }}>
              <Heading size="2" color="gray">Default template</Heading>
              <Text size="1" weight="light" color="gray" wrap="balance" as="p">Choose the template you want to use by default for note transcriptions.</Text>
            </Col>
            <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ paddingLeft: 10, textAlign: 'right' }}>
              <Select.Root defaultValue={defaultTemplate} onValueChange={(value) => updateDefaultTemplate(value)}>
                <Select.Trigger variant="solid" size='2' style={{ cursor: 'pointer' }} />
                <Select.Content align="start">
                  {NOTE_TEMPLATES.map((t, index) => (
                    <Select.Item key={index} value={t.name} style={{ cursor: 'pointer' }}>{t.label}</Select.Item>
                  ))}
                </Select.Content>
              </Select.Root>
            </Col>
          </Row>

          <Row style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start', marginLeft: 0, marginRight: 0, marginTop: 20 }}>
            <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ padding: 0 }}>
              <Heading size="2" color="gray">Default tone</Heading>
              <Text size="1" weight="light" color="gray" wrap="balance" as="p">Choose the tone you want to use by default for note transcriptions.</Text>
            </Col>
            <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ paddingLeft: 10, textAlign: 'right' }}>
              <Select.Root defaultValue={defaultTone} onValueChange={(value) => updateDefaultTone(value)}>
                <Select.Trigger variant="solid" size='2' style={{ cursor: 'pointer' }} />
                <Select.Content align="start">
                  {NOTE_TONES.map((t, index) => (
                    <Select.Item key={index} value={t} style={{ cursor: 'pointer' }}>{t.charAt(0).toUpperCase() + t.slice(1)}</Select.Item>
                  ))}
                </Select.Content>
              </Select.Root>
            </Col>
          </Row>

          <Row style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start', marginLeft: 0, marginRight: 0, marginTop: 20 }}>
            <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ padding: 0 }}>
              <Heading size="2" color="gray">Default language</Heading>
              <Text size="1" weight="light" color="gray" wrap="balance" as="p">Choose the language you want to use by default for note transcriptions.</Text>
            </Col>
            <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ paddingLeft: 10, textAlign: 'right' }}>
              <Select.Root defaultValue={defaultLanguage} onValueChange={(value) => updateDefaultLanguage(value)}>
                <Select.Trigger variant="solid" size='2' style={{ cursor: 'pointer' }} />
                <Select.Content align="start">
                  {NOTE_LANGUAGES.map((t, index) => (
                    <Select.Item key={index} value={t} style={{ cursor: 'pointer' }}>{t.charAt(0).toUpperCase() + t.slice(1)}</Select.Item>
                  ))}
                </Select.Content>
              </Select.Root>
            </Col>
          </Row>

          <Footer />
        </div>

        <Toaster position='bottom-right' toastOptions={{ className: 'toast', style: { background: 'var(--gray-3)', color: 'var(--gray-11)' } }} />

      </div>
    </div>
  )


}


