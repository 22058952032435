import { supabaseClient } from "../config/supabase";

/*
    Get user
    Parameters
        id
    Response
        user
*/

export const dbGetUser = async (user) => {

    const { data, error } = await supabaseClient
    .from('users')
    .select()
    .eq('email', user.email)
    .limit(1)
    .maybeSingle()

    if (error) { 
        return null;
    } else {
        if (data) {
            return data;
        } else {
            return null;
        }
    }

};

/*
    Create new user
    Parameters
        user
    Response
        true or false
*/

export const dbCreateUser = async(user) => {

    const { data, error } = await supabaseClient
    .from('users')
    .insert({
        id: user.id,
        email: user.email,
        full_name: user.user_metadata && user.user_metadata && user.user_metadata.full_name ? user.user_metadata.full_name : null,
        avatar_url: user.user_metadata && user.user_metadata && user.user_metadata.avatar_url ? user.user_metadata.avatar_url : null,
    })

    if (error) { 
        return false;
    } else {
        return true;
    }
};

/*
    Update user
    Parameters
        user id
        user object
    Response
        true or false
*/

export const dbUpdateUser = async(user_id, user) => {

    const { error } = await supabaseClient
    .from('users')
    .update(user)
    .eq('id', user_id)

    if (error) { 
        return null;
    } else {
        return true;
    }
}

/*
    Get notes
    Parameters
        user_id
    Response
        notes
*/

export const dbGetNotes = async(user_id) => {

    const { data, error } = await supabaseClient
    .from('notes')
    .select()
    .eq('created_by', user_id)
    .eq('is_trashed', false)
    .order('created_at', { ascending: false })

    if (error) { 
        return null;
    } else {
        return data;
    }
}

/*
    Create new note
    Parameters
        note object
    Response
        true or false
*/

export const dbCreateNote = async(note) => {

    const { data, error } = await supabaseClient
    .from('notes')
    .insert([note])

    if (error) { 
        return false;   
    } else {
        return true;
    }

};

/*      
    Get note
    Parameters
        id
    Response
        note
*/

export const dbGetNote = async(id) => {

    const { data, error } = await supabaseClient
    .from('notes')
    .select()
    .eq('id', id)
    .eq('is_trashed', false)
    .limit(1)
    .maybeSingle()

    if (error) { 
        return null;
    } else {
        return data;
    }

}

/*
    Update note
    Parameters
        id
        note object
    Response
        true or false
*/  

export const dbUpdateNote = async(id, note) => {

    const { data, error } = await supabaseClient
    .from('notes')
    .update(note)
    .eq('id', id)

    if (error) { 
        return false;
    } else {
        return true;
    }

}

/*
    Delete note
    Parameters
        id
        user_id
    Response
        true or false
*/  

export const dbDeleteNote = async(id, user_id) => {

    const { data, error } = await supabaseClient
    .from('notes')
    .delete()
    .eq('id', id)
    .eq('created_by', user_id)

    if (error) { 
        return null;
    } else {
        return true;
    }

}  

/*
    Trash note
    Parameters
        id
        user_id
    Response
        true or false
*/

export const dbTrashNote = async(id, user_id) => {

    const { data, error } = await supabaseClient
    .from('notes')
    .update({ is_trashed: true })
    .eq('id', id)
    .eq('created_by', user_id)

    if (error) { 
        return null;
    } else {
        return true;
    }

}

/*
    Get usage by month e.g. Jan 2024
    Parameters
        user_id
    Response
        usage [month, prompt_tokens, completition_tokens, total_tokens]
*/

export const dbGetUsage = async (user_id) => {

    const { data, error } = await supabaseClient
    .from('usage')
    .select(`
        created_at, model_id, total_tokens, prompt_tokens, completion_tokens
    `)
    .eq('created_by', user_id)

    if (error) { 
        return null;
    } else {
        if (data) {
            return data;
        } else {
            return null;
        }
    }

}




